import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const ChilliMaturityCategory = () => {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState({ name: '', min_days: '', max_days: '' });
    const [editingCategory, setEditingCategory] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli_maturity_category`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching chilli maturity categories:', error);
            setError('Failed to fetch categories. Please try again.');
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingCategory({ ...editingCategory, [name]: value });
        } else {
            setNewCategory({ ...newCategory, [name]: value });
        }
    };

    const handleAddCategory = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/chilli/chilli_maturity_category`, newCategory, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewCategory({ name: '', min_days: '', max_days: '' });
            fetchCategories();
        } catch (error) {
            console.error('Error adding chilli maturity category:', error);
            setError('Failed to add category. Please try again.');
        }
    };

    const handleUpdateCategory = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/chilli/chilli_maturity_category/${editingCategory.id}`, editingCategory, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingCategory(null);
            fetchCategories();
        } catch (error) {
            console.error('Error updating chilli maturity category:', error);
            setError('Failed to update category. Please try again.');
        }
    };

    const handleDeleteCategory = async (id) => {
        setError(null);
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/chilli_maturity_category/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchCategories();
        } catch (error) {
            console.error('Error deleting chilli maturity category:', error);
            setError('Failed to delete category. Please try again.');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 text-white">
            <h2 className="text-2xl font-bold mb-4">Chilli Maturity Categories</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            <form onSubmit={handleAddCategory} className="mb-8">
                <h3 className="text-xl font-bold mb-2">Add New Category</h3>
                <div className="grid grid-cols-3 gap-4">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={newCategory.name}
                        onChange={handleInputChange}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    />
                    <input
                        type="number"
                        name="min_days"
                        placeholder="Min Days"
                        value={newCategory.min_days}
                        onChange={handleInputChange}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    />
                    <input
                        type="number"
                        name="max_days"
                        placeholder="Max Days"
                        value={newCategory.max_days}
                        onChange={handleInputChange}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    />
                </div>
                <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                    Add Category
                </button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">Name</th>
                        <th className="p-2">Min Days</th>
                        <th className="p-2">Max Days</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category, index) => (
                        <tr 
                            key={category.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{category.name}</td>
                            <td className="p-2">{category.min_days}</td>
                            <td className="p-2">{category.max_days}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingCategory(category)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteCategory(category.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingCategory && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-800">
                        <h3 className="text-xl font-bold mb-4">Edit Category</h3>
                        <form onSubmit={handleUpdateCategory}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={editingCategory.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full bg-gray-700 text-white"
                                required
                            />
                            <input
                                type="number"
                                name="min_days"
                                placeholder="Min Days"
                                value={editingCategory.min_days}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full bg-gray-700 text-white"
                                required
                            />
                            <input
                                type="number"
                                name="max_days"
                                placeholder="Max Days"
                                value={editingCategory.max_days}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full bg-gray-700 text-white"
                                required
                            />
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingCategory(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChilliMaturityCategory;