import logo from '../assets/img/logo.png';
function Footer() {
  return (
      <>
      <footer className="bg-[#13161d] footer pt-14 relative"> 
        <div className="container mx-auto px-4 relative"> 
          <div className="pb-6 lg:pb-8">
            <div className="gap-9 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5"> 
              {/* <div><a href="#" className="inline-block"> <img src={logo} width={63} height={75} /></a>
                <div className="mt-8"> 
                  <div className="flex items-center">
                    <div className="mr-4"><i className="fa-lg fa-phone-alt fas py-3 rounded-bl text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h6 className="font-semibold roboto text-[#646473] text-xs">Call us 24/7</h6>
                      <p className="roboto text-opacity-55 text-white text-xs">1-123-456-7896</p>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <div className="mr-4"><i className="fa-lg fa-map-marker-alt fas py-3 rounded-bl text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h6 className="font-semibold roboto text-[#646473] text-xs">Location</h6>
                      <p className="roboto text-opacity-55 text-white text-xs">6320 lorum lipsum # 1123456 Australia,  91367</p>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <div className="mr-4"><i className="fa-envelope fa-lg fas py-3 rounded-bl text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <p className="roboto text-opacity-55 text-white text-xs">General Inquiries: info@chilliplantstore.com</p>
                    </div>
                  </div>                                 
                </div>                             
              </div>                          */}
              {/* <div>
                <h2 className="font-semibold mb-3 text-white text-xl uppercase lg:mb-6">Company</h2>
                <div> 
                  <div> 
                    <ul className="roboto text-xs"> 
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">About Us</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Contact Us</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Meet the Team</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Testimonials</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">FAQs</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Sitemap</a> 
                      </li>                                         
                    </ul>                                     
                  </div>                                 
                </div>                             
              </div>
              <div>
                <h2 className="font-semibold mb-3 text-white text-xl uppercase lg:mb-6">Products</h2>
                <div> 
                  <div> 
                    <ul className="roboto text-xs"> 
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Chilli Seeds By Country</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Super Hot Chilli Seeds</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Hot Chilli Seeds</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Medium Hot Chilli Seeds</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Mild Chilli Seeds</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Chilli Seeds By Species</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Annuum</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Baccatum</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Chinense</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Frutescens</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="text-opacity-55 text-white hover:text-[#ff0000]">Pubescens</a> 
                      </li>                                         
                    </ul>                                     
                  </div>                                 
                </div>                             
              </div>
              <div>
                <h2 className="font-semibold mb-3 text-white text-xl uppercase lg:mb-6">Support</h2>
                <div> 
                  <div> 
                    <ul className="roboto text-xs"> 
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">My Account</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Log In</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Sign Up</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">View Cart</a> 
                      </li>                                         
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Privacy Policy</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Shipping Information</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Track your order</a> 
                      </li>
                      <li className="mb-2"> <a href="#" className="hover:text-[#ff0000] text-opacity-55 text-white">Returns and Warranty Policy</a> 
                      </li>                                         
                    </ul>                                     
                  </div>                                 
                </div>                             
              </div>                         
              <div> 
                <h2 className="font-semibold mb-3 text-white text-xl uppercase">SUBSCRIBE OUR NEWSLETTER</h2>
                <p className="mb-3 roboto text-[#dddddd] text-opacity-40 text-xs">Be the first to receive the great deals and news</p>
                <form> 
                  <div className="grid"> 
                    <input className="appearance-none font-semibold outline-none placeholder-black placeholder-opacity-50 px-4 py-4 roboto rounded-md text-sm" placeholder="Enter your email address" type="email" /> 
                  </div>
                  <div className="grid mt-3"> 
                    <button href="#" className="bg-[#ff0000] font-bold inline-block px-6 py-3 rounded-md text-center text-white uppercase lg:px-10 lg:py-4" type="submit">Subscribe Now</button>                                     
                  </div>                                 
                </form>
                <p className="mt-3 roboto text-[#757584] text-xs">Your data will not be passed on to third parties</p> 
              </div>                          */}
            </div>
          </div>
          <hr className="border-opacity-5 border-white mb-4" />
          <div className="pb-8 pt-4 text-sm"> 
            <div className="flex flex-col flex-wrap items-center lg:flex-row lg:justify-between"> 
              <div> 
                <p className="roboto text-center text-sm text-white lg:text-left">&nbsp;© 2024 <a href="#" className="font-bold hover:text-[#ff0000]">www.chilli.ninja</a></p> 
              </div>
              {/* <div className="mt-6 lg:mt-0"> <a href="#" className="group inline-block"><i className="fa-2x fa-instagram fab text-white" /></a>
                <a href="#" className="group inline-block ml-3"><i className="fa-2x fa-facebook-square fab text-white" /></a>
                <a href="#" className="group inline-block ml-3"><i className="fa-2x fa-tiktok fab text-white" /></a>
                <a href="#" className="group inline-block ml-3"><i className="fa-2x fa-pinterest fab text-white" /></a>
              </div>                          */}
            </div>                     
          </div>
        </div>             
      </footer>              
      </>
  );
}
  
export default Footer;