import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const AccountAddresses = () => {
    const [addresses, setAddresses] = useState([]);
    const [newAddress, setNewAddress] = useState({
        unit: '',
        street: '',
        suburb_city: '',
        postcode: '',
        state_id: ''
    });
    const [editingAddress, setEditingAddress] = useState(null);
    const [states, setStates] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchAddresses();
        fetchStates();
    }, []);

    const fetchAddresses = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account_address`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAddresses(response.data);
        } catch (error) {
            console.error('Error fetching addresses:', error);
            setError('Failed to fetch addresses. Please try again.');
        }
    };

    const fetchStates = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account_address_au_state`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setStates(response.data);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingAddress({ ...editingAddress, [name]: value });
        } else {
            setNewAddress({ ...newAddress, [name]: value });
        }
    };

    const handleAddAddress = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/user/account_address`, newAddress, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewAddress({
                unit: '',
                street: '',
                suburb_city: '',
                postcode: '',
                state_id: ''
            });
            fetchAddresses();
        } catch (error) {
            console.error('Error adding address:', error);
            setError('Failed to add address. Please try again.');
        }
    };

    const handleUpdateAddress = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/user/account_address/${editingAddress.id}`, editingAddress, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingAddress(null);
            fetchAddresses();
        } catch (error) {
            console.error('Error updating address:', error);
            setError('Failed to update address. Please try again.');
        }
    };

    const handleDeleteAddress = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/account_address/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchAddresses();
        } catch (error) {
            console.error('Error deleting address:', error);
            setError('Failed to delete address. Please try again.');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Account Addresses</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            <form onSubmit={handleAddAddress} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Address</h3>
                <input
                    type="text"
                    name="unit"
                    placeholder="Unit"
                    value={newAddress.unit}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                />
                <input
                    type="text"
                    name="street"
                    placeholder="Street"
                    value={newAddress.street}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <input
                    type="text"
                    name="suburb_city"
                    placeholder="Suburb/City"
                    value={newAddress.suburb_city}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <input
                    type="text"
                    name="postcode"
                    placeholder="Postcode"
                    value={newAddress.postcode}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <select
                    name="state_id"
                    value={newAddress.state_id}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                >
                    <option value="">Select State</option>
                    {states.map(state => (
                        <option key={state.id} value={state.id}>{state.name}</option>
                    ))}
                </select>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Address</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Unit</th>
                        <th className="p-2">Street</th>
                        <th className="p-2">Suburb/City</th>
                        <th className="p-2">Postcode</th>
                        <th className="p-2">State</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {addresses.map((address, index) => (
                        <tr 
                            key={address.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{address.id}</td>
                            <td className="p-2">{address.unit || 'N/A'}</td>
                            <td className="p-2">{address.street}</td>
                            <td className="p-2">{address.suburb_city}</td>
                            <td className="p-2">{address.postcode}</td>
                            <td className="p-2">{states.find(s => s.id === address.state_id)?.name}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingAddress(address)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteAddress(address.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingAddress && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Address</h3>
                        <form onSubmit={handleUpdateAddress}>
                            <input
                                type="text"
                                name="unit"
                                placeholder="Unit"
                                value={editingAddress.unit}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                            />
                            <input
                                type="text"
                                name="street"
                                placeholder="Street"
                                value={editingAddress.street}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="suburb_city"
                                placeholder="Suburb/City"
                                value={editingAddress.suburb_city}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="postcode"
                                placeholder="Postcode"
                                value={editingAddress.postcode}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <select
                                name="state_id"
                                value={editingAddress.state_id}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            >
                                <option value="">Select State</option>
                                {states.map(state => (
                                    <option key={state.id} value={state.id}>{state.name}</option>
                                ))}
                            </select>
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingAddress(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountAddresses;