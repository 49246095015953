import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { parseISO } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import { ADMIN_API_URL } from '../../config/api';

const Chilli = () => {
    const [chillis, setChillis] = useState([]);
    const [newChilli, setNewChilli] = useState({
        name: '',
        description: '',
        maturity_category_id: '',
        species_id: '',
        heat_level_id: '',
        filial_id: '',
        parent_1_id: '',
        parent_2_id: '',
        height_category: '',
        frost_tolerance: '',
        germination_time: '',
        origin_country: '',
        position: '',
        soil: '',
        colour: '',
        lifecycle: '',
        flavours: []
    });
    const [editingChilli, setEditingChilli] = useState(null);
    const [relatedData, setRelatedData] = useState({});
    const [error, setError] = useState(null);
    const [flavours, setFlavours] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('chilli'); 
    const [showChilliForm, setShowChilliForm] = useState(false);

    // State for seedlings and seedling events
    const [seedlings, setSeedlings] = useState([]);
    const [seedlingEvents, setSeedlingEvents] = useState([]);
    const [newSeedlings, setNewSeedlings] = useState([{ chilli_id: '', status_id: null, date_planted: '', nfc_tag_id: '' }]);
    const [newSeedlingEvents, setNewSeedlingEvents] = useState([{ seedling_id: '', event_type_id: '', event_date: '', notes: '' }]);
    const [seedlingStatuses, setSeedlingStatuses] = useState([]);
    const [editingSeedling, setEditingSeedling] = useState(null);
    const [seedlingEventTypes, setSeedlingEventTypes] = useState([]);
    const [editingSeedlingEvent, setEditingSeedlingEvent] = useState(null);

    useEffect(() => {
        fetchChillis();
        fetchRelatedData();
        fetchFlavours();
        fetchSeedlings();
        fetchSeedlingEvents();
        fetchSeedlingStatuses();
        fetchSeedlingEventTypes();
    }, []);

    const fetchSeedlingStatuses = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/seedling_status`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSeedlingStatuses(response.data);
        } catch (error) {
            console.error('Error fetching seedling statuses:', error);
            setError('Failed to fetch seedling statuses. Please try again.');
        }
    };

    const fetchChillis = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Response Data:', response.data);
            const processedChillis = response.data.map(chilli => ({
                ...chilli,
                flavours: Array.isArray(chilli.flavours)
                    ? chilli.flavours.filter(f => f !== null).map(f => f.id)
                    : []
            }));
            console.log('Processed Chillis:', processedChillis);
            setChillis(processedChillis);
        } catch (error) {
            console.error('Error fetching chillis:', error);
            setError('Failed to fetch chillis. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRelatedData = async () => {
        setIsLoading(true);
        const tables = [
            'species', 'heat_level', 'filial', 'height_category', 'frost_tolerance',
            'germination_time', 'origin_country', 'position', 'soil', 'colour', 'lifecycle',
            'maturity_category'
        ];
        const token = localStorage.getItem('adminToken');
        const newRelatedData = {};

        try {
            for (let table of tables) {
                const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli_${table}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                newRelatedData[table] = response.data;
            }
            setRelatedData(newRelatedData);
        } catch (error) {
            console.error('Error fetching related data:', error);
            setError('Failed to fetch related data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFlavours = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli_flavour`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setFlavours(response.data);
        } catch (error) {
            console.error('Error fetching flavours:', error);
            setError('Failed to fetch flavours. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSeedlings = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/seedling`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSeedlings(response.data);
        } catch (error) {
            console.error('Error fetching seedlings:', error);
            setError('Failed to fetch seedlings. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSeedlingEvents = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/seedling_event`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSeedlingEvents(response.data);
        } catch (error) {
            console.error('Error fetching seedling events:', error);
            setError('Failed to fetch seedling events. Please try again.');
        }
    };

    const fetchSeedlingEventTypes = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/seedling_event_type`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSeedlingEventTypes(response.data);
        } catch (error) {
            console.error('Error fetching seedling event types:', error);
            setError('Failed to fetch seedling event types. Please try again.');
        }
    };
    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        const numericFields = ['species_id', 'heat_level_id', 'filial_id', 'parent_1_id', 'parent_2_id', 'height_category', 'frost_tolerance', 'germination_time', 'maturity_category_id'];
        
        let processedValue = value;
        if (numericFields.includes(name)) {
            processedValue = value === '' ? null : Number(value);
        }

        if (isEditing) {
            setEditingChilli(prev => ({ ...prev, [name]: processedValue }));
        } else {
            setNewChilli(prev => ({ ...prev, [name]: processedValue }));
        }
    };

    const handleFlavourChange = (e, isEditing = false) => {
        const flavourId = parseInt(e.target.value);
        const updater = isEditing ? setEditingChilli : setNewChilli;
        
        updater(prev => {
            const currentFlavours = prev.flavours || [];
            const newFlavours = currentFlavours.includes(flavourId)
                ? currentFlavours.filter(id => id !== flavourId)
                : [...currentFlavours, flavourId];
            console.log('Updated flavours:', newFlavours);
            return { ...prev, flavours: newFlavours };
        });
    };

    const handleAddChilli = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const token = localStorage.getItem('adminToken');
            const processedChilli = {
                ...Object.fromEntries(
                    Object.entries(newChilli).map(([key, value]) => [key, value === '' ? null : value])
                ),
                flavours: newChilli.flavours
            };
            console.log('Sending data:', processedChilli);
            const response = await axios.post(`${ADMIN_API_URL}/chilli/chilli`, processedChilli, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Server response:', response.data);
            setNewChilli({
                name: '',
                description: '',
                maturity_category_id: '',
                species_id: '',
                heat_level_id: '',
                filial_id: '',
                parent_1_id: '',
                parent_2_id: '',
                height_category: '',
                frost_tolerance: '',
                germination_time: '',
                origin_country: '',
                position: '',
                soil: '',
                colour: '',
                lifecycle: '',
                flavours: []
            });
            fetchChillis();
        } catch (error) {
            console.error('Error adding chilli:', error.response ? error.response.data : error);
            setError(error.response ? error.response.data.error : 'An unexpected error occurred while adding the chilli.');
        }
    };

    const handleUpdateChilli = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const token = localStorage.getItem('adminToken');
            const processedChilli = {
                ...Object.fromEntries(
                    Object.entries(editingChilli).map(([key, value]) => [key, value === '' ? null : value])
                ),
                flavours: editingChilli.flavours
            };
            console.log('Sending update data:', processedChilli);
            const response = await axios.put(`${ADMIN_API_URL}/chilli/chilli/${editingChilli.id}`, processedChilli, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Server response:', response.data);
            setEditingChilli(null);
            fetchChillis();
        } catch (error) {
            console.error('Error updating chilli:', error.response ? error.response.data : error);
            setError(error.response ? error.response.data.error : 'An unexpected error occurred while updating the chilli.');
        }
    };

    const handleDeleteChilli = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/chilli/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchChillis();
        } catch (error) {
            console.error('Error deleting chilli:', error);
            setError('Failed to delete chilli. Please try again.');
        }
    };

    const handleSeedlingInputChange = (index, e) => {
        const { name, value } = e.target;
        setNewSeedlings(prev => prev.map((seedling, i) => 
            i === index 
                ? { ...seedling, [name]: name === 'status_id' ? (value === '' ? null : parseInt(value)) : value } 
                : seedling
        ));
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // This will return 'YYYY-MM-DD'
    };

    const formatDateForDisplay = (dateString) => {
        if (!dateString) return '';
        return format(new Date(dateString), 'dd MMM yyyy'); // This will return format like '15 Sep 2024'
    };

    const formatDateToBrisbane = (dateString) => {
        console.log('Original dateString:', dateString); // Debugging log
        // Treat the date string as UTC
        const utcDate = new Date(dateString + 'Z');
        console.log('UTC date:', utcDate); // Debugging log
        const formattedDate = formatInTimeZone(utcDate, 'Australia/Brisbane', "dd MMM yyyy HH:mm:ss");
        console.log('Formatted date:', formattedDate); // Debugging log
        return formattedDate;
    };

    const handleEditClick = (seedling) => {
        setEditingSeedling({
            ...seedling,
            date_planted: formatDateForInput(seedling.date_planted),
            date_germinated: formatDateForInput(seedling.date_germinated),
            date_transplanted: formatDateForInput(seedling.date_transplanted),
            status_id: seedling.status_id
        });
    };

    const handleEditingSeedlingInputChange = (e) => {
        const { name, value } = e.target;
        setEditingSeedling(prev => ({
            ...prev,
            [name]: name === 'status_id' ? (value === '' ? null : parseInt(value)) : value
        }));
    };

    const handleSeedlingEventInputChange = (index, e) => {
        const { name, value } = e.target;
        setNewSeedlingEvents(prev => prev.map((event, i) => 
            i === index ? { ...event, [name]: name === 'event_type_id' || name === 'seedling_id' ? parseInt(value) : value } : event
        ));
    };

    const handleEditingSeedlingEventInputChange = (e) => {
        const { name, value } = e.target;
        setEditingSeedlingEvent(prev => ({
            ...prev,
            [name]: name === 'event_type_id' || name === 'seedling_id' ? parseInt(value) : value
        }));
    };

    const handleAddSeedlingRow = () => {
        setNewSeedlings(prev => [...prev, { chilli_id: '', status_id: null, date_planted: '' }]);
    };

    const handleAddSeedlingEventRow = () => {
        setNewSeedlingEvents(prev => [...prev, { seedling_id: '', event_type: '', event_date: '', notes: '' }]);
    };

    // Update the handleBulkAddSeedlings function
    const handleBulkAddSeedlings = async () => {
        if (newSeedlings.some(seedling => !seedling.chilli_id || seedling.status_id === null || !seedling.date_planted)) {
            setError('Please fill in all required fields for all seedlings.');
            return;
        }
    
        try {
            const token = localStorage.getItem('adminToken');
            console.log('Sending seedling data:', newSeedlings); // Log the data being sent
            const response = await axios.post(`${ADMIN_API_URL}/chilli/seedling`, newSeedlings, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Server response:', response.data);
            fetchSeedlings();
            setNewSeedlings([{ chilli_id: '', status_id: null, date_planted: '', nfc_tag_id: '' }]);
            setError(null);
        } catch (error) {
            console.error('Error adding seedlings:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                setError(error.response.data.error || 'Failed to add seedlings. Please try again.');
            } else if (error.request) {
                console.error('Error request:', error.request);
                setError('Network error. Please check your connection and try again.');
            } else {
                console.error('Error message:', error.message);
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    // Update the handleUpdateSeedling function
    const handleUpdateSeedling = async () => {
        if (!editingSeedling) return;
    
        try {
            const token = localStorage.getItem('adminToken');
            console.log('Sending updated seedling data:', editingSeedling); // Log the data being sent
            const response = await axios.put(`${ADMIN_API_URL}/chilli/seedling/${editingSeedling.id}`, editingSeedling, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Server response:', response.data);
            await fetchSeedlings();
            setEditingSeedling(null);
            setError(null);
        } catch (error) {
            console.error('Error updating seedling:', error);
            setError(error.response?.data?.error || 'An error occurred while updating the seedling.');
        }
    };

    const handleDeleteSeedling = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/seedling/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            await fetchSeedlings();
            await fetchSeedlingEvents();
            setError(null);
        } catch (error) {
            console.error('Error deleting seedling:', error);
            setError('Failed to delete seedling. Please try again.');
        }
    };

    const handleBulkAddSeedlingEvents = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            for (const event of newSeedlingEvents) {
                await axios.post(`${ADMIN_API_URL}/chilli/seedling_event`, {
                    seedling_id: event.seedling_id,
                    event_type_id: event.event_type_id,
                    event_date: new Date(event.event_date).toISOString(),
                    notes: event.notes
                }, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            }
            fetchSeedlingEvents();
            setNewSeedlingEvents([{ seedling_id: '', event_type_id: '', event_date: '', notes: '' }]);
        } catch (error) {
            console.error('Error adding seedling events:', error);
            setError('Failed to add seedling events. Please try again.');
        }
    };

    const handleUpdateSeedlingEvent = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const formattedEvent = {
                ...editingSeedlingEvent,
                event_date: new Date(editingSeedlingEvent.event_date).toISOString()
            };
            console.log('Sending to backend:', formattedEvent); // Log the data being sent
            await axios.put(`${ADMIN_API_URL}/chilli/seedling_event/${editingSeedlingEvent.id}`, formattedEvent, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchSeedlingEvents();
            setEditingSeedlingEvent(null);
        } catch (error) {
            console.error('Error updating seedling event:', error);
            setError('Failed to update seedling event. Please try again.');
        }
    };
    

    const handleDeleteSeedlingEvent = async (eventId) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/seedling_event/${eventId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchSeedlingEvents();
        } catch (error) {
            console.error('Error deleting seedling event:', error);
            setError('Failed to delete seedling event. Please try again.');
        }
    };

    const renderDropdown = (name, data, chilli, isEditing = false) => {
        if (name === 'heat_level_id') {
            return (
                <select
                    name={name}
                    value={chilli[name] || ''}
                    onChange={(e) => handleInputChange(e, isEditing)}
                    className="border p-2 w-full bg-gray-700 text-white"
                    required
                >
                    <option value="">Select Heat Level</option>
                    {data && data.map(item => (
                        <option key={item.id} value={item.id}>
                            {`${item.name} (${item.shu_min} - ${item.shu_max} SHU)`}
                        </option>
                    ))}
                </select>
            );
        }
    
        // For other dropdowns, keep the original rendering
        return (
            <select
                name={name}
                value={chilli[name] || ''}
                onChange={(e) => handleInputChange(e, isEditing)}
                className="border p-2 w-full bg-gray-700 text-white"
                required
            >
                <option value="">Select {name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}</option>
                {data && data.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </select>
        );
    };

    const renderParentDropdown = (name, chilliList, chilli, isEditing = false) => (
        <select
            name={name}
            value={chilli[name] || ''}
            onChange={(e) => handleInputChange(e, isEditing)}
            className="border p-2 w-full bg-gray-700 text-white"
        >
            <option value="">Select Parent</option>
            {chilliList.map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
            ))}
        </select>
    );

    const renderForm = (chilli, isEditing = false, onSubmit) => (
        <form onSubmit={onSubmit} className="mb-8">
            <h3 className="text-xl font-bold mb-2">{isEditing ? 'Edit Chilli' : 'Add New Chilli'}</h3>
            <div className="grid grid-cols-2 gap-4">
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={chilli.name}
                    onChange={(e) => handleInputChange(e, isEditing)}
                    className="border p-2 bg-gray-700 text-white"
                    required
                />
                {renderDropdown('maturity_category_id', relatedData.maturity_category, chilli, isEditing)}
                {renderDropdown('species_id', relatedData.species, chilli, isEditing)}
                {renderDropdown('heat_level_id', relatedData.heat_level, chilli, isEditing)}
                {renderDropdown('filial_id', relatedData.filial, chilli, isEditing)}
                {renderParentDropdown('parent_1_id', chillis, chilli, isEditing)}
                {renderParentDropdown('parent_2_id', chillis, chilli, isEditing)}
                {renderDropdown('height_category', relatedData.height_category, chilli, isEditing)}
                {renderDropdown('frost_tolerance', relatedData.frost_tolerance, chilli, isEditing)}
                {renderDropdown('germination_time', relatedData.germination_time, chilli, isEditing)}
                {renderDropdown('origin_country', relatedData.origin_country, chilli, isEditing)}
                {renderDropdown('position', relatedData.position, chilli, isEditing)}
                {renderDropdown('soil', relatedData.soil, chilli, isEditing)}
                {renderDropdown('colour', relatedData.colour, chilli, isEditing)}
                {renderDropdown('lifecycle', relatedData.lifecycle, chilli, isEditing)}
                <textarea
                    name="description"
                    placeholder="Description"
                    value={chilli.description}
                    onChange={(e) => handleInputChange(e, isEditing)}
                    className="border p-2 col-span-2 bg-gray-700 text-white"
                    required
                />
                <div className="col-span-2">
                    <h4 className="text-white mb-2">Flavours:</h4>
                    {flavours.map(flavour => {
                        const isChecked = Array.isArray(chilli.flavours) && chilli.flavours.includes(flavour.id);
                        return (
                            <label key={flavour.id} className="block text-white">
                                <input
                                    type="checkbox"
                                    value={flavour.id}
                                    checked={isChecked}
                                    onChange={(e) => handleFlavourChange(e, isEditing)}
                                />
                                {flavour.name}
                            </label>
                        );
                    })}
                </div>
            </div>
            <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                {isEditing ? 'Save Changes' : 'Add Chilli'}
            </button>
            {isEditing && (
                <button 
                    type="button" 
                    onClick={() => {
                        setEditingChilli(null);
                    }}
                    className="mt-4 ml-2 bg-gray-500 text-white px-4 py-2 rounded"
                >
                    Cancel
                </button>
            )}
        </form>
    );

    const renderSeedlingForm = () => (
        <div className="mb-8">
            <h3 className="text-xl font-bold mb-2">Add Seedlings</h3>
            {newSeedlings.map((seedling, index) => (
                <div key={index} className="grid grid-cols-4 gap-4 mb-4">
                    <select
                        name="chilli_id"
                        value={seedling.chilli_id}
                        onChange={(e) => handleSeedlingInputChange(index, e)}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    >
                        <option value="">Select Chilli</option>
                        {chillis.map(chilli => (
                            <option key={chilli.id} value={chilli.id}>{chilli.name}</option>
                        ))}
                    </select>
                    <select
                        name="status_id"
                        value={seedling.status_id || ''}
                        onChange={(e) => handleSeedlingInputChange(index, e)}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    >
                        <option value="">Select Status</option>
                        {seedlingStatuses.map(status => (
                            <option key={status.id} value={status.id}>{status.name}</option>
                        ))}
                    </select>
                    <input
                        type="date"
                        name="date_planted"
                        value={seedling.date_planted}
                        onChange={(e) => handleSeedlingInputChange(index, e)}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    />
                    <input
                        type="text"
                        name="nfc_tag_id"
                        value={seedling.nfc_tag_id}
                        onChange={(e) => handleSeedlingInputChange(index, e)}
                        placeholder="NFC Tag ID"
                        className="border p-2 bg-gray-700 text-white"
                    />
                    {index === newSeedlings.length - 1 && (
                        <button 
                            type="button" 
                            onClick={handleAddSeedlingRow}
                            className="bg-green-500 text-white px-4 py-2 rounded"
                        >
                            +
                        </button>
                    )}
                </div>
            ))}
            <button 
                type="button" 
                onClick={handleBulkAddSeedlings}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                disabled={newSeedlings.some(seedling => !seedling.chilli_id || seedling.status_id === null || !seedling.date_planted)}
            >
                Add Seedlings
            </button>
        </div>
    );

    const renderSeedlingEventForm = () => (
        <div className="mb-8">
            <h3 className="text-xl font-bold mb-2">Add Seedling Events</h3>
            {newSeedlingEvents.map((event, index) => (
                <div key={index} className="grid grid-cols-4 gap-4 mb-4">
                    <select
                        name="seedling_id"
                        value={event.seedling_id}
                        onChange={(e) => handleSeedlingEventInputChange(index, e)}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    >
                        <option value="">Select Seedling</option>
                        {seedlings.map(seedling => (
                            <option key={seedling.id} value={seedling.id}>
                                {chillis.find(c => c.id === seedling.chilli_id)?.name || 'Unknown'} - {seedling.date_planted}
                            </option>
                        ))}
                    </select>
                    <select
                        name="event_type_id"
                        value={event.event_type_id}
                        onChange={(e) => handleSeedlingEventInputChange(index, e)}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    >
                        <option value="">Select Event Type</option>
                        {seedlingEventTypes.map(type => (
                            <option key={type.id} value={type.id}>{type.name}</option>
                        ))}
                    </select>
                    <input
                        type="datetime-local"
                        name="event_date"
                        value={event.event_date}
                        onChange={(e) => handleSeedlingEventInputChange(index, e)}
                        className="border p-2 bg-gray-700 text-white"
                        required
                    />
                    <input
                        type="text"
                        name="notes"
                        value={event.notes}
                        onChange={(e) => handleSeedlingEventInputChange(index, e)}
                        placeholder="Notes"
                        className="border p-2 bg-gray-700 text-white"
                    />
                </div>
            ))}
            <button 
                type="button" 
                onClick={handleBulkAddSeedlingEvents}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
                Add Seedling Events
            </button>
        </div>
    );

    const renderSeedlingEventsTable = () => (
        <table className="w-full text-white text-left">
            <thead>
                <tr className="bg-gray-700">
                    <th className="p-2">Seedling</th>
                    <th className="p-2">Event Type</th>
                    <th className="p-2">Event Date</th>
                    <th className="p-2">Notes</th>
                    <th className="p-2">Actions</th>
                </tr>
            </thead>
            <tbody>
                {seedlingEvents.map((event, index) => (
                    <tr 
                        key={event.id}
                        className={`
                            ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                            hover:bg-gray-600 transition-colors duration-200
                        `}
                    >
                        <td className="p-2">
                            {chillis.find(c => c.id === seedlings.find(s => s.id === event.seedling_id)?.chilli_id)?.name || 'Unknown'}
                        </td>
                        <td className="p-2">{seedlingEventTypes.find(t => t.id === event.event_type_id)?.name || 'Unknown'}</td>
                        <td className="p-2">{formatDateToBrisbane(event.event_date)}</td>
                        <td className="p-2">{event.notes || 'N/A'}</td>
                        <td className="p-2">
                            <button onClick={() => setEditingSeedlingEvent(event)} className="text-blue-500 mr-2">Edit</button>
                            <button onClick={() => handleDeleteSeedlingEvent(event.id)} className="text-red-500">Delete</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const renderEditSeedlingEventForm = () => {
        if (!editingSeedlingEvent) return null;

        const formattedDate = editingSeedlingEvent.event_date 
            ? formatInTimeZone(parseISO(editingSeedlingEvent.event_date), 'Australia/Brisbane', "yyyy-MM-dd'T'HH:mm")
            : '';

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-800">
                    <h3 className="text-lg font-bold mb-4">Edit Seedling Event</h3>
                    <form onSubmit={(e) => { e.preventDefault(); handleUpdateSeedlingEvent(); }}>
                        <select
                            name="seedling_id"
                            value={editingSeedlingEvent.seedling_id}
                            onChange={handleEditingSeedlingEventInputChange}
                            className="border p-2 w-full mb-2 bg-gray-700 text-white"
                            required
                        >
                            <option value="">Select Seedling</option>
                            {seedlings.map(seedling => (
                                <option key={seedling.id} value={seedling.id}>
                                    {chillis.find(c => c.id === seedling.chilli_id)?.name || 'Unknown'} - {seedling.date_planted}
                                </option>
                            ))}
                        </select>
                        <select
                            name="event_type_id"
                            value={editingSeedlingEvent.event_type_id}
                            onChange={handleEditingSeedlingEventInputChange}
                            className="border p-2 w-full mb-2 bg-gray-700 text-white"
                            required
                        >
                            <option value="">Select Event Type</option>
                            {seedlingEventTypes.map(type => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </select>
                        <input
                            type="datetime-local"
                            name="event_date"
                            value={formattedDate}
                            onChange={handleEditingSeedlingEventInputChange}
                            className="border p-2 w-full mb-2 bg-gray-700 text-white"
                            required
                        />
                        <input
                            type="text"
                            name="notes"
                            value={editingSeedlingEvent.notes || ''}
                            onChange={handleEditingSeedlingEventInputChange}
                            placeholder="Notes"
                            className="border p-2 w-full mb-4 bg-gray-700 text-white"
                        />
                        <div className="flex justify-between">
                            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                            <button onClick={() => setEditingSeedlingEvent(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return <div className="text-white">Loading...</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8 text-white">
            <h2 className="text-2xl font-bold mb-4">Chilli Management</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            {/* Tab buttons */}
            <div className="mb-4">
                <button 
                    onClick={() => setActiveTab('chilli')}
                    className={`mr-2 px-4 py-2 rounded ${activeTab === 'chilli' ? 'bg-blue-500' : 'bg-gray-700'}`}
                >
                    Chilli Varieties
                </button>
                <button 
                    onClick={() => setActiveTab('seedling')}
                    className={`px-4 py-2 rounded ${activeTab === 'seedling' ? 'bg-blue-500' : 'bg-gray-700'}`}
                >
                    Seedlings & Events
                </button>
            </div>
    
            {/* Chilli section */}
            {activeTab === 'chilli' && (
                <>
                    {!showChilliForm && !editingChilli && (
                        <button 
                            onClick={() => setShowChilliForm(true)}
                            className="mb-4 bg-green-500 text-white px-4 py-2 rounded"
                        >
                            Add Chilli
                        </button>
                    )}
    
                    {(showChilliForm || editingChilli) && (
                        <div className="mb-4">
                            {editingChilli ? (
                                renderForm(editingChilli, true, handleUpdateChilli)
                            ) : (
                                renderForm(newChilli, false, handleAddChilli)
                            )}
                            <button 
                                onClick={() => {
                                    setShowChilliForm(false);
                                    setEditingChilli(null);
                                }}
                                className="mt-2 bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Cancel
                            </button>
                        </div>
                    )}
    
                    <h3 className="text-xl font-bold mb-2">Chilli Varieties</h3>
                    <table className="w-full text-white text-left mb-8">
                        <thead>
                            <tr className="bg-gray-700">
                                <th className="p-2">Name</th>
                                <th className="p-2">Species</th>
                                <th className="p-2">Heat Level</th>
                                <th className="p-2">Flavours</th>
                                <th className="p-2 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chillis.map((chilli, index) => (
                                <tr 
                                    key={chilli.id}
                                    className={`
                                        ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                        hover:bg-gray-600 transition-colors duration-200
                                    `}
                                >
                                    <td className="p-2">{chilli.name || 'N/A'}</td>
                                    <td className="p-2">{relatedData.species?.find(s => s?.id === chilli.species_id)?.name || 'N/A'}</td>
                                    <td className="p-2">{relatedData.heat_level?.find(h => h?.id === chilli.heat_level_id)?.name || 'N/A'}</td>
                                    <td className="p-2">
                                        {chilli.flavours?.map(flavourId => 
                                            flavours.find(f => f.id === flavourId)?.name
                                        ).filter(Boolean).join(', ') || 'N/A'}
                                    </td>
                                    <td className="p-2 text-right">
                                        <button onClick={() => {
                                            const chilliToEdit = chillis.find(c => c.id === chilli.id);
                                            setEditingChilli({
                                                ...chilliToEdit,
                                                flavours: chilliToEdit.flavours || []
                                            });
                                            setShowChilliForm(true);
                                        }} className="text-blue-500 mr-2">Edit</button>
                                        <button onClick={() => handleDeleteChilli(chilli.id)} className="text-red-500">Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
    
            {/* Seedling section */}
            {activeTab === 'seedling' && (
                <>
                    {renderSeedlingForm()}
                    
                    <h3 className="text-xl font-bold mb-2">Seedlings</h3>
                    <table className="w-full text-white text-left mb-8">
                        <thead>
                            <tr className="bg-gray-700">
                                <th className="p-2">Chilli</th>
                                <th className="p-2">Status</th>
                                <th className="p-2">Date Planted</th>
                                <th className="p-2">Date Germinated</th>
                                <th className="p-2">Date Transplanted</th>
                                <th className="p-2">NFC Tag ID</th>
                                <th className="p-2">Unique ID</th>
                                <th className="p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {seedlings.map((seedling, index) => (
                                <tr 
                                    key={seedling.id}
                                    className={`
                                        ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                        hover:bg-gray-600 transition-colors duration-200
                                    `}
                                >
                                    <td className="p-2">{chillis.find(c => c.id === seedling.chilli_id)?.name || 'Unknown'}</td>
                                    <td className="p-2">
                                        {editingSeedling && editingSeedling.id === seedling.id ? (
                                            <select
                                                name="status_id"
                                                value={editingSeedling.status_id || ''}
                                                onChange={handleEditingSeedlingInputChange}
                                                className="border p-2 bg-gray-700 text-white"
                                            >
                                                {seedlingStatuses.map(status => (
                                                    <option key={status.id} value={status.id}>{status.name}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            seedlingStatuses.find(s => s.id === seedling.status_id)?.name
                                        )}
                                    </td>
                                    <td className="p-2">
                                        {editingSeedling && editingSeedling.id === seedling.id ? (
                                            <input
                                                type="date"
                                                name="date_planted"
                                                value={formatDateForInput(editingSeedling.date_planted) || ''}
                                                onChange={handleEditingSeedlingInputChange}
                                                className="border p-2 bg-gray-700 text-white"
                                            />
                                        ) : (
                                            formatDateForDisplay(seedling.date_planted)
                                        )}
                                    </td>
                                    <td className="p-2">
                                        {editingSeedling && editingSeedling.id === seedling.id ? (
                                            <input
                                                type="date"
                                                name="date_germinated"
                                                value={formatDateForInput(editingSeedling.date_germinated) || ''}
                                                onChange={handleEditingSeedlingInputChange}
                                                className="border p-2 bg-gray-700 text-white"
                                            />
                                        ) : (
                                            formatDateForDisplay(seedling.date_germinated) || 'N/A'
                                        )}
                                    </td>
                                    <td className="p-2">
                                        {editingSeedling && editingSeedling.id === seedling.id ? (
                                            <input
                                                type="date"
                                                name="date_transplanted"
                                                value={formatDateForInput(editingSeedling.date_transplanted) || ''}
                                                onChange={handleEditingSeedlingInputChange}
                                                className="border p-2 bg-gray-700 text-white"
                                            />
                                        ) : (
                                            formatDateForDisplay(seedling.date_transplanted) || 'N/A'
                                        )}
                                    </td>
                                    <td className="p-2">
                                        {editingSeedling && editingSeedling.id === seedling.id ? (
                                            <input
                                                type="text"
                                                name="nfc_tag_id"
                                                value={editingSeedling.nfc_tag_id || ''}
                                                onChange={handleEditingSeedlingInputChange}
                                                className="border p-2 bg-gray-700 text-white"
                                            />
                                        ) : (
                                            seedling.nfc_tag_id || 'N/A'
                                        )}
                                    </td>
                                    <td className="p-2">
                                        {editingSeedling && editingSeedling.id === seedling.id ? (
                                            editingSeedling.unique_id || 'N/A'
                                        ) : (
                                            seedling.unique_id || 'N/A'
                                        )}
                                    </td>
                                    <td className="p-2">
                                        {editingSeedling && editingSeedling.id === seedling.id ? (
                                            <>
                                                <button onClick={handleUpdateSeedling} className="text-green-500 mr-2">Save</button>
                                                <button onClick={() => setEditingSeedling(null)} className="text-red-500">Cancel</button>
                                            </>
                                        ) : (
                                            <>
                                                <button onClick={() => handleEditClick(seedling)} className="text-blue-500 mr-2">Edit</button>
                                                <button onClick={() => handleDeleteSeedling(seedling.id)} className="text-red-500">Delete</button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
    
                    {renderSeedlingEventForm()}
    
                    <h3 className="text-xl font-bold mb-2">Seedling Events</h3>
                    {renderSeedlingEventsTable()}
                    {editingSeedlingEvent && renderEditSeedlingEventForm()}
                </>
            )}
        </div>
    );
};

export default Chilli;