import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

import ChilliSpecies from './chilli-species';
import ChilliHeatLevel from './chilli-heat-level';
import ChilliFilial from './chilli-filial';
import ChilliHeightCategory from './chilli-height-category';
import ChilliFrostTolerance from './chilli-frost-tolerance';
import ChilliGerminationTime from './chilli-germination-time';
import ChilliOriginCountry from './chilli-origin-country';
import ChilliPosition from './chilli-position';
import ChilliSoil from './chilli-soil';
import ChilliColour from './chilli-colour';
import ChilliLifecycle from './chilli-lifecycle';
import Chilli from './chilli';
import ChilliFlavour from './chilli-flavour';
import ChilliMaturityCategory from './chilli-maturity-category';
import ChilliSeedlingEventTypes from './chilli-seedling-event-types';

const ChilliDashboard = () => {
    const [activeComponent, setActiveComponent] = useState('ChilliSpecies');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('adminToken');
        if (!token) {
            navigate('/admin/login');
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
    };

    const renderActiveComponent = () => {
        switch(activeComponent) {
            case 'ChilliSpecies': return <ChilliSpecies />;
            case 'ChilliHeatLevel': return <ChilliHeatLevel />;
            case 'ChilliFilial': return <ChilliFilial />;
            case 'ChilliHeightCategory': return <ChilliHeightCategory />;
            case 'ChilliFrostTolerance': return <ChilliFrostTolerance />;
            case 'ChilliGerminationTime': return <ChilliGerminationTime />;
            case 'ChilliOriginCountry': return <ChilliOriginCountry />;
            case 'ChilliPosition': return <ChilliPosition />;
            case 'ChilliSoil': return <ChilliSoil />;
            case 'ChilliColour': return <ChilliColour />;
            case 'ChilliLifecycle': return <ChilliLifecycle />;
            case 'Chilli': return <Chilli />;
            case 'ChilliFlavour': return <ChilliFlavour />;
            case 'ChilliMaturityCategory': return <ChilliMaturityCategory />;
            case 'ChilliSeedlingEventTypes': return <ChilliSeedlingEventTypes />;
            default: return <ChilliSpecies />;
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6 text-white">
                <h1 className="text-2xl font-bold">Admin Dashboard</h1>
                <div>
                <Link 
                        to="/admin/users/dashboard"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                        Users Dashboard
                    </Link>
                    <Link 
                        to="/admin/chillis/dashboard"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                        Chilli Dashboard
                    </Link>
                <button 
                    onClick={handleLogout}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                    Logout
                </button>
                </div>
            </div>
            
            <div className="mb-4">
                {['ChilliSpecies', 'ChilliHeatLevel', 'ChilliFilial', 'ChilliHeightCategory', 'ChilliFrostTolerance', 
                  'ChilliGerminationTime', 'ChilliOriginCountry', 'ChilliPosition', 'ChilliSoil', 'ChilliColour', 
                  'ChilliLifecycle', 'Chilli', 'ChilliFlavour', 'ChilliMaturityCategory', 'ChilliSeedlingEventTypes'].map(component => (
                    <button 
                        key={component}
                        onClick={() => setActiveComponent(component)} 
                        className={`mr-2 mb-2 ${activeComponent === component ? 'bg-blue-500 text-white' : 'bg-gray-200'} px-4 py-2 rounded text-black`}
                    >
                        {component}
                    </button>
                ))}
            </div>

            {renderActiveComponent()}
        </div>
    );
};

export default ChilliDashboard;