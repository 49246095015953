import Header from '../components/header';
import Footer from '../components/footer';

function Contact() { 
  return (
    <>
      <Header></Header>
      { /* HERO */ }
      <section className="faqs-home pt-10"> 
        <div className="container mx-auto px-4 text-white">
          <div>
            <nav aria-label="Breadcrumb"> 
              <ol className="flex flex-wrap font-bold items-center roboto"> 
                <li className="inline-flex"> <a href="#" className="inline-flex items-center text-sm text-white hover:underline">Home</a> 
                </li>                             
                <li aria-current="page"> 
                  <div className="flex items-center"> 
                    <svg className="h-3 mx-1 text-white w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10"> 
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 9 4-4-4-4" /> 
                    </svg>                                     
                    <span className="text-opacity-65 text-sm text-white">Best Selling Seeds</span> 
                  </div>                                 
                </li>                             
              </ol>                         
            </nav>
          </div>                 
        </div>             
      </section>
      <section className="faqs-home pb-20 pt-10"> 
        <div className="container mx-auto px-4 text-white"> 
          <div className="gap-8 grid lg:grid-cols-3">
            <div className="lg:col-span-2">
              <div className="rounded-lg lg:p-6 lg:shadow-2xl xl:p-10 lg:bg-[#232329]"> 
                <h2 className="font-bold mb-1 text-2xl text-white uppercase lg:text-4xl">CONTACT US AND GET A MORE DETAILS</h2>
                <p className="font-medium mb-6 roboto text-opacity-45 text-sm text-white">Contact us through the following 24/7, and if we don’t answer immediately, we guarantee to get back to you within 12 hours.</p> 
                <form className="gap-4 grid lg:grid-cols-2"> 
                  <div> 
                    <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="First Name *" required type="text" />
                  </div>
                  <div> 
                    <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="Last Name *" required type="text" />
                  </div>                                 
                  <div> 
                    <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="Email *" type="email" required />
                  </div>
                  <div> 
                    <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="Phone Number *" type="tel" required />
                  </div>
                  <div> 
                    <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="Enter your Address *" type="text" required />
                  </div>
                  <div> 
                    <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="ZIP code *" type="number" required />
                  </div>                                 
                  <div className="lg:col-span-2"> 
                    <textarea rows={6} className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="Text Your Message" defaultValue={""} />
                  </div>
                  <div> <a href="#" className="bg-[#ff0000] border border-[#ff0000] border-solid font-bold inline-block px-8 py-3 roboto rounded text-base text-center text-white hover:border-white">Submit a Message</a> 
                  </div>                                 
                </form>                             
              </div>
            </div>
            <div>
              <div className="rounded-lg lg:p-6 lg:shadow-2xl xl:p-10 lg:bg-[#232329]">
                <h2 className="font-bold mb-1 text-2xl text-white uppercase lg:text-4xl">LETS TALK</h2>
                <p className="font-medium mb-8 roboto text-opacity-45 text-sm text-white">If you have startling idea, start a meeting right away or schedule a meeting at your convenience.</p>
                <div>
                  <div className="flex items-center">
                    <div className="mr-4">
                      <i className="fa-lg fa-phone-alt fas text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h5 className="font-semibold roboto text-base text-opacity-35 text-white">Phone number</h5>
                      <p className="font-semibold roboto text-lg text-opacity-65 text-white">0452 391 605</p>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <div className="mr-4">
                      <i className="fa-envelope fa-lg fas text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h5 className="font-semibold roboto text-base text-opacity-35 text-white">Email with us</h5>
                      <p className="font-semibold roboto text-base text-opacity-65 text-white">enquiry@chilliplants.com.au</p>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <div className="mr-4">
                      <i className="fa-lg fa-map-marker-alt fas py-3 rounded-bl text-white" />
                    </div>
                    <div className="flex-1 flex-grow">
                      <h5 className="font-semibold roboto text-base text-opacity-35 text-white">Address</h5>
                      <p className="font-semibold roboto text-base text-opacity-65 text-white">12th Lorum lipsum, simply dummy Mascow street, United Kingdom</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>             
      </section>                  
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default Contact;