import Header from '../components/header';
import Footer from '../components/footer';
import placeholder from '../assets/img/1400x800.png';
import ribbon from '../assets/img/ribbon.png';

function Product() { 
  return (
    <>
      <Header></Header>
      { /* HERO */ }
      <section className="pt-10"> 
          <div className="container mx-auto px-4 text-white">
            <div>
              <nav aria-label="Breadcrumb"> 
                <ol className="flex flex-wrap font-bold items-center roboto"> 
                  <li className="inline-flex"> <a href="#" className="inline-flex items-center text-sm text-white hover:underline">Home</a> 
                  </li>                             
                  <li aria-current="page"> 
                    <div className="flex items-center"> 
                      <svg className="h-3 mx-1 text-white w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10"> 
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 9 4-4-4-4" /> 
                      </svg>                                     
                      <span className="text-opacity-65 text-sm text-white">Best Selling Seeds</span> 
                    </div>                                 
                  </li>                             
                </ol>                         
              </nav>
            </div>                 
          </div>             
        </section>
        <section className="faqs-home pb-20 pt-10"> 
          <div className="container mx-auto px-4 text-white">
            <div className="gap-8 grid lg:grid-cols-4 xl:grid-cols-5">
              <section>
                <div className="mb-8">
                  <h1 className="font-medium mb-4 text-white text-xl">Filter By</h1>
                  <div className="mb-6 lg:mb-8">
                    <form> 
                      <div className="grid relative"> 
                        <input className="appearance-none bg-[#1a1a1a] border-[#323238] font-bold outline-none pl-5 placeholder-opacity-65 placeholder-white pr-8 py-4 roboto rounded-md text-sm w-full" placeholder="Search" type="text" />
                        <div className="-translate-y-1/2 absolute right-4 top-1/2">
                          <i className="fa-search fa-sm fas" />
                        </div>
                      </div>                                                                                                               
                    </form>                                 
                  </div>
                </div>
                <div className="mb-8">
                  <h1 className="font-medium mb-4 text-white text-xl">Product By Category</h1>
                  <div className="mb-6 lg:mb-8">
                    <div className="flex mb-2">
                      <div className="flex-shrink-0">
                        <input type="checkbox" name="category" className="bg-[#323238] border border-[#d6d6d6] border-opacity-25 border-solid h-5 rounded w-5" />
                      </div>
                      <div className="flex-grow px-2">
                        <label className="font-bold roboto text-sm">Chilli Seeds By Country</label>
                      </div>
                      <div className="flex-shrink-0"><span className="roboto text-[#999999] text-sm">22</span>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="flex-shrink-0">
                        <input type="checkbox" name="category" className="bg-[#323238] border border-[#d6d6d6] border-opacity-25 border-solid h-5 rounded w-5" />
                      </div>
                      <div className="flex-grow px-2">
                        <label className="font-bold roboto text-sm">Chilli Plants</label>
                      </div>
                      <div className="flex-shrink-0"><span className="roboto text-[#999999] text-sm">22</span>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="flex-shrink-0">
                        <input type="checkbox" name="category" className="bg-[#323238] border border-[#d6d6d6] border-opacity-25 border-solid h-5 rounded w-5" />
                      </div>
                      <div className="flex-grow px-2">
                        <label className="font-bold roboto text-sm">Chilli Seeds</label>
                      </div>
                      <div className="flex-shrink-0"><span className="roboto text-[#999999] text-sm">22</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <h1 className="font-medium mb-4 text-white text-xl">Price Range</h1>
                  <div className="mb-6 lg:mb-8">
                    <div className="flex mb-2">
                      <div className="flex-shrink-0">
                        <input type="checkbox" name="price" className="bg-[#323238] border border-[#d6d6d6] border-opacity-25 border-solid h-5 rounded w-5" />
                      </div>
                      <div className="flex-grow px-2">
                        <label className="font-bold roboto text-sm">$$00 - $$99</label>
                      </div>
                      <div className="flex-shrink-0"><span className="roboto text-[#999999] text-sm">22</span>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="flex-shrink-0">
                        <input type="checkbox" name="price" className="bg-[#323238] border border-[#d6d6d6] border-opacity-25 border-solid h-5 rounded w-5" />
                      </div>
                      <div className="flex-grow px-2">
                        <label className="font-bold roboto text-sm">$$00 - $$99</label>
                      </div>
                      <div className="flex-shrink-0"><span className="roboto text-[#999999] text-sm">22</span>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="flex-shrink-0">
                        <input type="checkbox" name="price" className="bg-[#323238] border border-[#d6d6d6] border-opacity-25 border-solid h-5 rounded w-5" />
                      </div>
                      <div className="flex-grow px-2">
                        <label className="font-bold roboto text-sm">$$00 - $$99</label>
                      </div>
                      <div className="flex-shrink-0"><span className="roboto text-[#999999] text-sm">22</span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="lg:col-span-3 xl:col-span-4">
                <div className="items-center justify-between mb-8 md:flex lg:mb-12">
                  <div>
                    <div className="flex-wrap mb-2 xl:flex xl:items-center">
                      <div>
                        <h3 className="text-3xl text-white uppercase xl:text-4xl">BEST SELLING <strong className="inline-block text-[#ff0000]">Seeds</strong></h3>
                      </div>
                      <div className="hidden pl-6 pr-3 xl:block"> <span className="font-bold roboto text-base">|</span>
                      </div>
                      <div> 
                        <h5 className="font-bold roboto text-base">Totally 663 Seeds collections</h5>
                      </div>
                    </div>
                    <p className="font-medium mb-3 roboto text-[#777777] text-sm lg:text-base">Because with us, your story is more than just words on a page.</p>
                  </div>
                  <div className="flex items-center"><span className="font-medium roboto text-base text-nowrap text-white">Sort by</span>
                    <div className="ml-4"> 
                        <div className="grid"> 
                          <select className="appearance-none bg-[#1a1a1a] border-[#323238] font-bold min-w-56 outline-none pl-5 placeholder-opacity-65 placeholder-white pr-8 py-4 roboto rounded-md text-sm w-full">
                            <option value="Best Selling">Best Selling</option>
                            <option value="Best Selling">Best Selling</option>
                            <option value="Best Selling">Best Selling</option>
                          </select>
                        </div>                                                                                                                                                                
                    </div>
                  </div>
                </div>
                <div className="gap-6 grid md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"> 
                  <div className="bg-[#232329] card-seeds gap-4 grid rounded-lg shadow-black shadow-lg">
                    <div className="relative">
                      <img src={placeholder} width={280} height={280} className="block h-auto object-cover rounded-lg w-full" />
                      <div className="-left-2 absolute top-3">
                        <img src={ribbon} width={66} height={25} className="object-cover" /><span className="absolute font-bold inline-block left-1 roboto text-center text-white text-wrap text-xs top-1">10% off</span>
                      </div>
                    </div>
                    <div className="pb-4 px-4">
                      <h2 className="font-bold mb-2 roboto text-base text-white">A mild chilli very popular in Mexican cuisine,</h2>
                      <div className="flex flex-wrap items-center">
                        <div><span className="font-bold inline-block roboto text-[#ffa64d] text-lg">$8.99</span>
                        </div>
                        <div className="ml-2"><span className="font-bold inline-block line-through roboto text-[#757584] text-base">$9.99</span>
                        </div>
                        <div className="ml-2"><span className="font-medium inline-block roboto text-white text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <p className="line-clamp-3 mb-2 roboto text-opacity-45 text-white text-xs">A mild chilli very popular in Mexican ine, thislli very popular in Mexican cuisine, this chilli can be eate...</p>
                      <div className="flex flex-wrap items-center mb-2">
                        <div>
                          <div>
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                          </div>
                        </div>
                        <div className="ml-3"><span className="inline-block roboto text-[#aaaaaa] text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <form className="gap-3 grid grid-cols-3 items-center">
                        <div className="grid">
                          <select className="bg-[#1c1c1c] text-white">
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                          </select>
                        </div>
                        <div className="col-span-2 grid">
                          <button className="bg-[#ff0000] border border-solid border-transparent font-bold inline-block p-3 roboto rounded shadow-lg text-center text-white text-wrap text-xs uppercase hover:border-white" type="submit"><i className="fa-cart-plus fa-lg fas" /> ADD TO CART
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="bg-[#232329] card-seeds gap-4 grid rounded-lg shadow-black shadow-lg">
                    <div className="relative">
                      <img src={placeholder} width={280} height={280} className="block h-auto object-cover rounded-lg w-full" />
                      <div className="-left-2 absolute top-3">
                        <img src={ribbon} width={66} height={25} className="object-cover" /><span className="absolute font-bold inline-block left-1 roboto text-center text-white text-wrap text-xs top-1">10% off</span>
                      </div>
                    </div>
                    <div className="pb-4 px-4">
                      <h2 className="font-bold mb-2 roboto text-base text-white">A mild chilli very popular in Mexican cuisine,</h2>
                      <div className="flex flex-wrap items-center">
                        <div><span className="font-bold inline-block roboto text-[#ffa64d] text-lg">$8.99</span>
                        </div>
                        <div className="ml-2"><span className="font-bold inline-block line-through roboto text-[#757584] text-base">$9.99</span>
                        </div>
                        <div className="ml-2"><span className="font-medium inline-block roboto text-white text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <p className="line-clamp-3 mb-2 roboto text-opacity-45 text-white text-xs">A mild chilli very popular in Mexican ine, thislli very popular in Mexican cuisine, this chilli can be eate...</p>
                      <div className="flex flex-wrap items-center mb-2">
                        <div>
                          <div>
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                          </div>
                        </div>
                        <div className="ml-3"><span className="inline-block roboto text-[#aaaaaa] text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <form className="gap-3 grid grid-cols-3 items-center">
                        <div className="grid">
                          <select className="bg-[#1c1c1c] text-white">
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                          </select>
                        </div>
                        <div className="col-span-2 grid">
                          <button className="bg-[#ff0000] border border-solid border-transparent font-bold inline-block p-3 roboto shadow-lg text-center text-white text-wrap text-xs uppercase hover:border-white" type="submit"><i className="fa-cart-plus fa-lg fas" /> ADD TO CART
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="bg-[#232329] card-seeds gap-4 grid rounded-lg shadow-black shadow-lg">
                    <div>
                      <img src={placeholder} width={280} height={280} className="block h-auto object-cover rounded-lg w-full" />
                    </div>
                    <div className="pb-4 px-4">
                      <h2 className="font-bold mb-2 roboto text-base text-white">A mild chilli very popular in Mexican cuisine,</h2>
                      <div className="flex flex-wrap items-center">
                        <div><span className="font-bold inline-block roboto text-[#ffa64d] text-lg">$8.99</span>
                        </div>
                        <div className="ml-2"><span className="font-bold inline-block line-through roboto text-[#757584] text-base">$9.99</span>
                        </div>
                        <div className="ml-2"><span className="font-medium inline-block roboto text-white text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <p className="line-clamp-3 mb-2 roboto text-opacity-45 text-white text-xs">A mild chilli very popular in Mexican ine, thislli very popular in Mexican cuisine, this chilli can be eate...</p>
                      <div className="flex flex-wrap items-center mb-2">
                        <div>
                          <div>
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                          </div>
                        </div>
                        <div className="ml-3"><span className="inline-block roboto text-[#aaaaaa] text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <form className="gap-3 grid grid-cols-3 items-center">
                        <div className="grid">
                          <select className="bg-[#1c1c1c] text-white">
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                          </select>
                        </div>
                        <div className="col-span-2 grid">
                          <button className="bg-[#ff0000] border border-solid border-transparent font-bold inline-block p-3 roboto shadow-lg text-center text-white text-wrap text-xs uppercase hover:border-white" type="submit"><i className="fa-cart-plus fa-lg fas" /> ADD TO CART
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="bg-[#232329] card-seeds gap-4 grid rounded-lg shadow-black shadow-lg">
                    <div>
                      <img src={placeholder} width={280} height={280} className="block h-auto object-cover rounded-lg w-full" />
                    </div>
                    <div className="pb-4 px-4">
                      <h2 className="font-bold mb-2 roboto text-base text-white">A mild chilli very popular in Mexican cuisine,</h2>
                      <div className="flex flex-wrap items-center">
                        <div><span className="font-bold inline-block roboto text-[#ffa64d] text-lg">$8.99</span>
                        </div>
                        <div className="ml-2"><span className="font-bold inline-block line-through roboto text-[#757584] text-base">$9.99</span>
                        </div>
                        <div className="ml-2"><span className="font-medium inline-block roboto text-white text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <p className="line-clamp-3 mb-2 roboto text-opacity-45 text-white text-xs">A mild chilli very popular in Mexican ine, thislli very popular in Mexican cuisine, this chilli can be eate...</p>
                      <div className="flex flex-wrap items-center mb-2">
                        <div>
                          <div>
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                            <i className="fa-star fas text-xs text-yellow-400" />
                          </div>
                        </div>
                        <div className="ml-3"><span className="inline-block roboto text-[#aaaaaa] text-xs">56746 Reviews</span>
                        </div>
                      </div>
                      <form className="gap-3 grid grid-cols-3 items-center">
                        <div className="grid">
                          <select className="bg-[#1c1c1c] text-white">
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                          </select>
                        </div>
                        <div className="col-span-2 grid">
                          <button className="bg-[#ff0000] border border-solid border-transparent font-bold inline-block p-3 roboto shadow-lg text-center text-white text-wrap text-xs uppercase hover:border-white" type="submit"><i className="fa-cart-plus fa-lg fas" /> ADD TO CART
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>                             
                </div>
                <div className="mt-12">
                  <nav className="-space-x-px flex flex-wrap isolate rounded-md shadow-sm" aria-label="Pagination"> <a href="#" className="bg-[#2f3646] font-bold inline-flex items-center mb-2 mr-4 px-3 py-2 relative roboto rounded-md text-white text-xs uppercase focus:outline-offset-0 focus:z-20 lg:px-4"> <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="8px" height="7px" className="mr-3">
                        <image x="0px" y="0px" width="8px" height="7px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAHCAQAAACfUMTVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfoCQUWIypxtMB5AAAANklEQVQI11XMsREAIRADMfnov2TwB5B8pNlkU4gj1xSKXOefZlRFnh1LbH1KGduxjK13OrZcP0CEGAID1l+aAAAAAElFTkSuQmCC" />
                      </svg> <span>Previous</span> </a> 
                    {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}                                 <a href="#" aria-current="page" className="bg-[#ff0000] font-semibold inline-flex items-center mb-2 px-3 py-2 relative rounded text-sm text-white z-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600 focus-visible:outline-offset-2 focus:z-20 lg:px-4">1</a> <a href="#" className="font-bold inline-flex items-center mb-2 px-3 py-2 relative roboto rounded text-opacity-50 text-sm text-white focus:outline-offset-0 focus:z-20 hover:text-opacity-100 lg:px-4">2</a> <a href="#" className="font-bold hidden items-center mb-2 px-3 py-2 relative roboto rounded text-opacity-50 text-sm text-white focus:outline-offset-0 focus:z-20 hover:text-opacity-100 md:inline-flex lg:px-4">3</a> <a href="#" className="font-bold hidden items-center mb-2 px-3 py-2 relative roboto rounded text-opacity-50 text-sm text-white focus:outline-offset-0 focus:z-20 hover:text-opacity-100 md:inline-flex lg:px-4">8</a> <a href="#" className="font-bold inline-flex items-center mb-2 px-3 py-2 relative roboto rounded text-opacity-50 text-sm text-white focus:outline-offset-0 focus:z-20 hover:text-opacity-100 lg:px-4">9</a>
                    <span className="font-semibold inline-flex items-center mb-2 px-3 py-2 relative text-gray-700 text-sm focus:outline-offset-0 lg:px-4">...</span>
                    <a href="#" className="font-bold inline-flex items-center mb-2 px-3 py-2 relative roboto rounded text-opacity-50 text-sm text-white focus:outline-offset-0 focus:z-20 hover:text-opacity-100 lg:px-4">10</a>
                    <a href="#" className="bg-[#2f3646] font-bold inline-flex items-center mb-2 ml-4 px-3 py-2 relative roboto rounded-md text-white text-xs uppercase focus:outline-offset-0 focus:z-20 lg:px-4"> <span>Next</span><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="8px" height="7px" className="ml-3">
                        <image x="0px" y="0px" width="8px" height="7px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAHCAQAAACfUMTVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfoCQUWJyD1DexjAAAAPElEQVQI1zXLQQ4DQQgDwQLm/y8OsAcUX6y22rErEEZAol36hie1VVITe/pKYe7yw/v3M0JZLZVJoQyHfLRKFBCEhknCAAAAAElFTkSuQmCC" />
                      </svg> </a> 
                  </nav>
                </div>
              </section>
            </div>                 
          </div>             
        </section>                 
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default Product;