import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const ChilliHeatLevel = () => {
    const [heatLevels, setHeatLevels] = useState([]);
    const [newHeatLevel, setNewHeatLevel] = useState({ name: '', shu_min: '', shu_max: '' });
    const [editingHeatLevel, setEditingHeatLevel] = useState(null);

    useEffect(() => {
        fetchHeatLevels();
    }, []);

    const fetchHeatLevels = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli_heat_level`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setHeatLevels(response.data);
        } catch (error) {
            console.error('Error fetching chilli heat levels:', error);
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingHeatLevel({ ...editingHeatLevel, [name]: value });
        } else {
            setNewHeatLevel({ ...newHeatLevel, [name]: value });
        }
    };

    const handleAddHeatLevel = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/chilli/chilli_heat_level`, newHeatLevel, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewHeatLevel({ name: '', shu_min: '', shu_max: '' });
            fetchHeatLevels();
        } catch (error) {
            console.error('Error adding chilli heat level:', error);
        }
    };

    const handleUpdateHeatLevel = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/chilli/chilli_heat_level/${editingHeatLevel.id}`, editingHeatLevel, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingHeatLevel(null);
            fetchHeatLevels();
        } catch (error) {
            console.error('Error updating chilli heat level:', error);
        }
    };

    const handleDeleteHeatLevel = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/chilli_heat_level/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchHeatLevels();
        } catch (error) {
            console.error('Error deleting chilli heat level:', error);
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Chilli Heat Levels</h2>
            
            <form onSubmit={handleAddHeatLevel} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Heat Level</h3>
                <input
                    type="text"
                    name="name"
                    placeholder="Heat Level Name"
                    value={newHeatLevel.name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2"
                    required
                />
                <input
                    type="number"
                    name="shu_min"
                    placeholder="Minimum SHU"
                    value={newHeatLevel.shu_min}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2"
                    required
                />
                <input
                    type="number"
                    name="shu_max"
                    placeholder="Maximum SHU"
                    value={newHeatLevel.shu_max}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Heat Level</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Name</th>
                        <th className="p-2">Min SHU</th>
                        <th className="p-2">Max SHU</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {heatLevels.map((item, index) => (
                        <tr 
                            key={item.id} 
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{item.id}</td>
                            <td className="p-2">{item.name}</td>
                            <td className="p-2">{item.shu_min}</td>
                            <td className="p-2">{item.shu_max}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingHeatLevel(item)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteHeatLevel(item.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingHeatLevel && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Heat Level</h3>
                        <form onSubmit={handleUpdateHeatLevel}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Heat Level Name"
                                value={editingHeatLevel.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="number"
                                name="shu_min"
                                placeholder="Minimum SHU"
                                value={editingHeatLevel.shu_min}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="number"
                                name="shu_max"
                                placeholder="Maximum SHU"
                                value={editingHeatLevel.shu_max}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingHeatLevel(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChilliHeatLevel;