import { NavLink, Outlet, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { useState } from "react";
function Header() {
  const location = useLocation();
  const activeKey = location.pathname === '/' ? '/home' : location.pathname;
  const [show, setShow] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const navLinkStyles = ({ isActive }) => {
    return isActive ? "p-2 font-bold lg:font-normal text-[#bd080c] lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]" : "p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]";
  }
  return (
      <>
      <header> 
        <section className="bg-[#bd080c] hidden py-2 lg:block"> 
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-5 items-center"> 
              <div />
              <div className="col-span-3 text-center"><a href="#" className="font-bold group inline-flex items-center text-white"> <i className="fa-shipping-fast fas mr-2" /><span className="group-hover:text-yellow-300 md:text-xs lg:text-base">FREE SHIPPING ON ALL ORDERS IN THE AUSTARLIA – USE CODE SHIPFREE AT CHECKOUT!</span> </a>
              </div>
              <div>
                <div className="flex justify-end"><a href="#" className="group inline-flex items-center ml-2 text-white"> <i className="fa-instagram fab text-lg group-hover:text-yellow-400" /></a><a href="#" className="font-bold group inline-flex items-center ml-3 text-lg text-white"> <i className="fa-facebook-square fab group-hover:text-yellow-400 text-lg" /></a>
                </div>
              </div>
            </div>
          </div>                 
        </section>
        <section className="bg-[#1a1a1a] hidden py-4 lg:flex"> 
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-3 items-center" data-name="nav-menu"> 
              <div><a href="#" className="font-bold group inline-flex items-center text-[#929292]"> <i className="fa-phone-alt fas mr-2" /><span className="group-hover:text-white">(123) 456-7892</span> </a>
              <a href="#" className="font-bold group inline-flex items-center ml-4 text-[#929292]"> <i className="fa-envelope fas mr-2" /><span className="group-hover:text-white">support@chillininja.com</span> </a>
              </div>
              <div className="text-center"><a href={activeKey} className="inline-block navbar-brand"> <img src={logo} width={85} height={100} /></a>
              </div>                         
              <div className="flex items-center text-right lg:justify-end">
                <div className="relative inline-block text-left"> 
                  <div> 
                    <button id="btn-dropdown" onClick={() => setDropdown(!dropdown)} type="button" className="font-bold gap-x-1.5 group inline-flex items-center px-3 py-2 text-[#929292]">
                      <i className="fa-user far" /> <span className="hidden group-hover:text-white lg:inline-block">SIGNIN</span> 
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"> 
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" /> 
                      </svg>
                    </button>                                     
                  </div>                                 
                  <div className={`absolute bg-white mt-2 origin-top-right right-0 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg w-56 z-10 ${dropdown ? '' : 'hidden'} focus:outline-none `} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}> 
                    <div className="py-1" role="none"> 
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-0">Account settings</a> <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-1">Support</a> <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-2">License</a> 
                      <form method="POST" action="#" role="none"> 
                        <button type="submit" className="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-3">Sign out</button>                                             
                      </form>                                         
                    </div>                                     
                  </div>                                 
                </div>
                <div className="inline-flex items-center ml-4">
                  <span className="font-bold hidden lg:inline-block mr-2 text-[#929292]">MY BASKET (0)</span>
                  <div className="inline-block pr-4 relative">
                    <button type="button" className="bg-black border border-solid border-white font-bold gap-x-1.5 h-8 inline-flex items-center justify-center rounded-full text-[#929292] w-8 hover:text-white hover:bg-[#ff0000] hover:border-[#ff0000]" id="btn-dropdown"> <i className="fa-shopping-cart fa-xs fas" />
                    </button><span className="-top-2 absolute bg-[#ff0000] font-bold h-5 inline-flex items-center justify-center roboto rounded-full text-[10px] text-white w-5">14</span>
                  </div>
                </div>
              </div>
            </div>                     
          </div>                 
        </section>
        <nav className="bg-black hidden py-2 lg:flex"> 
          <div className="container mx-auto px-4 w-full">
            <div data-name="nav-menu" className="grid items-center lg:grid-cols-6 xl:grid-cols-8"> 
              <div></div>
              <div className="lg:col-span-4 xl:col-span-6">
                <div className="text-center"> 
                  <NavLink to="/home" className={navLinkStyles}>Home</NavLink>
                  <NavLink to="/about" className={navLinkStyles}>About</NavLink>
                  <a href="#" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">CHILLI SEEDS</a>
                  <a href="#" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">CHILLI PLANTS</a>
                  <NavLink to="/product" className={navLinkStyles}>OUR PRODUCTS</NavLink>
                  <a href="#" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">REVIEWS</a>
                  <a href="/faqs" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">FAQ</a>
                  <NavLink to="/blog" className={navLinkStyles}>Blog</NavLink>
                  <NavLink to="/contact" className={navLinkStyles}>Contact Us</NavLink>
                </div>
              </div>                         
              <div className="text-center">
                <button className="inline-flex text-white" type="button"> <i className="fa-search fas text-xl" /></button>
              </div>
            </div>                     
          </div>                 
        </nav>
        <nav className="bg-[#1a1a1a] py-4 lg:hidden"> 
          <div className="container mx-auto px-4 w-full">
            <div className="flex items-center justify-between" data-name="nav-menu"> 
              <div className="flex">                
                <button className="mr-4" onClick={() => setShow(!show)}>
                  <svg width={30} height={23} viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1_341)">
                      <path d="M30 0H0V4.6H30V0Z" fill="white" />
                      <path d="M30 9.2H0V13.8H30V9.2Z" fill="white" />
                      <path d="M30 18.4H0V23H30V18.4Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_341">
                        <rect width={30} height={23} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <a href="#" className="inline-block mr-2 navbar-brand"> <img src={logo} width={38} height={45} /></a>
              </div>
              <div className="flex gap-6 items-center">
                <button className="inline-flex text-white" type="button"> <i className="fa-search fas text-2xl" />
                </button>
                <div className="inline-block text-white relative"> 
                  <div> 
                    <button id="btn-dropdown" type="button" className="font-bold gap-x-0.5 inline-flex items-center" onClick={() => setDropdown(!dropdown)} >
                      <i className="fa-user far text-2xl" /> <span className="hidden lg:inline-block">SIGNIN</span> 
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"> 
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" /> 
                      </svg>
                    </button>                                     
                  </div>                                 
                  <div className={`absolute bg-white mt-2 origin-top-right right-0 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg w-56 z-10 ${dropdown ? '' : 'hidden'} focus:outline-none `} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}> 
                    <div className="py-1" role="none">  
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-0">Account settings</a> 
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-1">Support</a> 
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-2">License</a> 
                      <form method="POST" action="#" role="none"> 
                        <button type="submit" className="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabIndex={-1} id="menu-item-3">Sign out</button>                                             
                      </form>                                         
                    </div>                                     
                  </div>                                 
                </div>
                <div className="inline-flex items-center">
                  <span className="font-bold hidden lg:inline-block mr-2 text-[#929292]">MY BASKET (0)</span>
                  <div className="inline-block pr-4 relative">
                    <button type="button" className="bg-black border border-solid border-white font-bold gap-x-1.5 h-8 inline-flex items-center justify-center rounded-full text-white w-8" id="btn-dropdown"> <i className="fa-shopping-cart fa-xs fas" />
                    </button><span className="-top-2 absolute bg-[#ff0000] font-bold h-4 inline-flex items-center justify-center roboto rounded-full text-white text-xs w-4">4</span>
                  </div>
                </div>
              </div>
              <div id="navcol-1" className={`basis-full bg-white fixed flex-col flex-grow h-full ${show ? '' : 'hidden'} left-0 lg:basis-auto lg:bg-transparent lg:h-auto lg:p-0 lg:static lg:w-auto lg:z-0 navbar-collapse px-3 py-4 top-0 w-full z-10`}>
                <div className="flex justify-end lg:hidden"> 
                  <button className="p-2 xl:px-4" type="button" onClick={() => setShow(!show)}>
                    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                      <g clipPath="url(#clip0_1_388)"> 
                        <path d="M24.7443 21.2027L3.53113 -0.0105438L-0.00440592 3.52499L21.2088 24.7382L24.7443 21.2027Z" fill="black" /> 
                        <path d="M21.2027 -0.00437129L-0.010498 21.2088L3.52504 24.7444L24.7382 3.53116L21.2027 -0.00437129Z" fill="black" /> 
                      </g>                                         
                      <defs> 
                        <clipPath id="clip0_1_388"> 
                          <rect width="24.75" height="24.75" fill="black" /> 
                        </clipPath>                                             
                      </defs>                                         
                    </svg>                                     
                  </button>                                 
                </div>
                <div className="flex flex-col md:mx-auto lg:flex-row"> 
                  <NavLink to="/home" className={navLinkStyles}>Home</NavLink>
                  <NavLink to="/about" className={navLinkStyles}>About</NavLink>
                  <a href="#" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">CHILLI SEEDS</a>
                  <a href="#" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">CHILLI PLANTS</a>
                  <NavLink to="/product" className={navLinkStyles}>OUR PRODUCTS</NavLink>
                  <a href="#" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">REVIEWS</a>
                  <a href="#" className="p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]">FAQ</a>
                  <NavLink to="/blog" className={navLinkStyles}>Blog</NavLink>
                  <NavLink to="/contact" className={navLinkStyles}>Contact Us</NavLink>
                </div>
              </div>                         
            </div>                     
          </div>                 
        </nav>             
      </header>        
      </>
  );
}
  
export default Header;