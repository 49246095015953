import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const ChilliFlavour = () => {
    const [flavours, setFlavours] = useState([]);
    const [newFlavour, setNewFlavour] = useState({ name: '' });
    const [editingFlavour, setEditingFlavour] = useState(null);

    useEffect(() => {
        fetchFlavours();
    }, []);

    const fetchFlavours = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli_flavour`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setFlavours(response.data);
        } catch (error) {
            console.error('Error fetching chilli flavours:', error);
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingFlavour({ ...editingFlavour, [name]: value });
        } else {
            setNewFlavour({ ...newFlavour, [name]: value });
        }
    };

    const handleAddFlavour = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/chilli/chilli_flavour`, newFlavour, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewFlavour({ name: '' });
            fetchFlavours();
        } catch (error) {
            console.error('Error adding chilli flavour:', error);
        }
    };

    const handleUpdateFlavour = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/chilli/chilli_flavour/${editingFlavour.id}`, editingFlavour, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingFlavour(null);
            fetchFlavours();
        } catch (error) {
            console.error('Error updating chilli flavour:', error);
        }
    };

    const handleDeleteFlavour = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/chilli_flavour/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchFlavours();
        } catch (error) {
            console.error('Error deleting chilli flavour:', error);
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Chilli Flavours</h2>
            
            <form onSubmit={handleAddFlavour} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Flavour</h3>
                <input
                    type="text"
                    name="name"
                    placeholder="Flavour Name"
                    value={newFlavour.name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Flavour</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Name</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {flavours.map((flavour, index) => (
                        <tr 
                            key={flavour.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{flavour.id}</td>
                            <td className="p-2">{flavour.name}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingFlavour(flavour)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteFlavour(flavour.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingFlavour && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Flavour</h3>
                        <form onSubmit={handleUpdateFlavour}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Flavour Name"
                                value={editingFlavour.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingFlavour(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChilliFlavour;