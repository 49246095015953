import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const ChilliSeedlingEventTypes = () => {
    const [eventTypes, setEventTypes] = useState([]);
    const [newEventType, setNewEventType] = useState({ name: '', description: '' });
    const [editingEventType, setEditingEventType] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchEventTypes();
    }, []);

    const fetchEventTypes = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/seedling_event_type`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEventTypes(response.data);
        } catch (error) {
            console.error('Error fetching seedling event types:', error);
            setError('Failed to fetch seedling event types. Please try again.');
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingEventType({ ...editingEventType, [name]: value });
        } else {
            setNewEventType({ ...newEventType, [name]: value });
        }
    };

    const handleAddEventType = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/chilli/seedling_event_type`, newEventType, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewEventType({ name: '', description: '' });
            fetchEventTypes();
        } catch (error) {
            console.error('Error adding seedling event type:', error);
            setError('Failed to add seedling event type. Please try again.');
        }
    };

    const handleUpdateEventType = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/chilli/seedling_event_type/${editingEventType.id}`, editingEventType, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingEventType(null);
            fetchEventTypes();
        } catch (error) {
            console.error('Error updating seedling event type:', error);
            setError('Failed to update seedling event type. Please try again.');
        }
    };

    const handleDeleteEventType = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/seedling_event_type/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchEventTypes();
        } catch (error) {
            console.error('Error deleting seedling event type:', error);
            setError('Failed to delete seedling event type. Please try again.');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Seedling Event Types</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            <form onSubmit={handleAddEventType} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Event Type</h3>
                <input
                    type="text"
                    name="name"
                    placeholder="Event Type Name"
                    value={newEventType.name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <input
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={newEventType.description}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Event Type</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">Name</th>
                        <th className="p-2">Description</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {eventTypes.map((eventType, index) => (
                        <tr 
                            key={eventType.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{eventType.name}</td>
                            <td className="p-2">{eventType.description}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingEventType(eventType)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteEventType(eventType.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingEventType && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-800">
                        <h3 className="text-lg font-bold mb-4">Edit Event Type</h3>
                        <form onSubmit={handleUpdateEventType}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Event Type Name"
                                value={editingEventType.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full bg-gray-700 text-white"
                                required
                            />
                            <input
                                type="text"
                                name="description"
                                placeholder="Description"
                                value={editingEventType.description}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full bg-gray-700 text-white"
                                required
                            />
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingEventType(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChilliSeedlingEventTypes;