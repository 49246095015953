import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const AccountUsers = () => {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ account_id: '', password: '', role: '' });
    const [editingUser, setEditingUser] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchUsers();
        fetchAccounts();
        fetchRoles();
    }, []);

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account_user`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users. Please try again.');
        }
    };

    const fetchAccounts = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAccounts(response.data);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const fetchRoles = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account_user_role`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingUser({ ...editingUser, [name]: value });
        } else {
            setNewUser({ ...newUser, [name]: value });
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/user/account_user`, newUser, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewUser({ account_id: '', password: '', role: '' });
            fetchUsers();
        } catch (error) {
            console.error('Error adding user:', error);
            setError('Failed to add user. Please try again.');
        }
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/user/account_user/${editingUser.id}`, editingUser, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingUser(null);
            fetchUsers();
        } catch (error) {
            console.error('Error updating user:', error);
            setError('Failed to update user. Please try again.');
        }
    };

    const handleDeleteUser = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/user/account_user/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
            setError('Failed to delete user. Please try again.');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Account Users</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            <form onSubmit={handleAddUser} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New User</h3>
                <select
                    name="account_id"
                    value={newUser.account_id}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                >
                    <option value="">Select Account</option>
                    {accounts.map(account => (
                        <option key={account.id} value={account.id}>{account.first_name} {account.last_name}</option>
                    ))}
                </select>
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={newUser.password}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <select
                    name="role"
                    value={newUser.role}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                >
                    <option value="">Select Role</option>
                    {roles.map(role => (
                        <option key={role.id} value={role.id}>{role.name}</option>
                    ))}
                </select>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add User</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Account</th>
                        <th className="p-2">Role</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr 
                            key={user.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{user.id}</td>
                            <td className="p-2">{accounts.find(a => a.id === user.account_id)?.first_name} {accounts.find(a => a.id === user.account_id)?.last_name}</td>
                            <td className="p-2">{roles.find(r => r.id === user.role)?.name}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingUser(user)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteUser(user.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingUser && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit User</h3>
                        <form onSubmit={handleUpdateUser}>
                            <select
                                name="account_id"
                                value={editingUser.account_id}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            >
                                <option value="">Select Account</option>
                                {accounts.map(account => (
                                    <option key={account.id} value={account.id}>{account.first_name} {account.last_name}</option>
                                ))}
                            </select>
                            <input
                                type="password"
                                name="password"
                                placeholder="New Password (leave blank to keep current)"
                                value={editingUser.password || ''}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                            />
                            <select
                                name="role"
                                value={editingUser.role}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            >
                                <option value="">Select Role</option>
                                {roles.map(role => (
                                    <option key={role.id} value={role.id}>{role.name}</option>
                                ))}
                            </select>
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingUser(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountUsers;