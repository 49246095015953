import Header from '../components/header';
import Footer from '../components/footer';
import placeholder from '../assets/img/1400x800.png';

function Blogdetail() { 
  return (
    <>
      <Header></Header>
      { /* HERO */ }
      <section className="hero-blog-detail py-16 lg:py-28"> 
          <div className="container mx-auto px-4 w-full"> 
            <div className="max-w-[1020px] mx-auto text-center"> 
              <h1 className="font-bold text-4xl text-white lg:text-6xl">Chili peppers, also spelled chile or chilli, are varieties of the berry-fruit of plants from the genus.</h1> 
            </div>                 
          </div>             
        </section>
        <section className="faqs-home pt-10"> 
          <div className="container mx-auto px-4 text-white">
            <div>
              <nav aria-label="Breadcrumb"> 
                <ol className="flex flex-wrap font-bold items-center roboto"> 
                  <li className="inline-flex"> <a href="#" className="inline-flex items-center text-sm text-white hover:underline">Home</a> 
                  </li>                             
                  <li aria-current="page"> 
                    <div className="flex items-center"> 
                      <svg className="h-3 mx-1 text-white w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10"> 
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 9 4-4-4-4" /> 
                      </svg>                                     <span className="text-opacity-65 text-sm text-white">Best Selling Seeds</span> 
                    </div>                                 
                  </li>                             
                </ol>                         
              </nav>
            </div>                 
          </div>             
        </section>
        <section className="faqs-home pb-20 pt-10"> 
          <div className="container mx-auto px-4 text-white">
            <div>
              <div className="gap-8 grid lg:grid-cols-3 2xl:grid-cols-4">
                <section className="lg:col-span-2 2xl:col-span-3">
                  <div className="mb-16 lg:mb-20">
                    <h2 className="font-bold mb-4 text-3xl text-white lg:text-5xl">Chili peppers, also spelled chile or chilli, are varieties of the berry-fruit of plants.</h2>
                    <p className="mb-4 roboto text-xs">Posted on May 1, 2020 In Articles </p>
                    <p className="line-clamp-6 mb-2 roboto text-[#646472] text-sm">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.&nbsp;In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.</p>
                    <p className="line-clamp-6 mb-2 roboto text-[#646472] text-sm">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.&nbsp;In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.</p>
                    <p className="line-clamp-6 mb-2 roboto text-[#646472] text-sm">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.&nbsp;In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.</p>
                    <div className="grid my-6 lg:mt-8">
                      <img src="assets/img/news-detail.png" />
                    </div>
                    <p className="line-clamp-6 mb-2 roboto text-[#646472] text-sm">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.&nbsp;In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.</p>
                    <p className="line-clamp-6 mb-2 roboto text-[#646472] text-sm">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.&nbsp;In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.</p>
                  </div>
                  <div className="mb-16 lg:mb-20">
                    <form> 
                      <h2 className="font-bold mb-6 text-2xl text-white lg:text-4xl">LEAVE A MESSAGE</h2>
                      <div className="gap-4 grid lg:grid-cols-3">
                        <div> 
                          <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-[#858585] px-5 py-4 roboto rounded text-sm w-full" placeholder="First Name" required /> 
                        </div>
                        <div> 
                          <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-[#858585] px-5 py-4 roboto rounded text-sm w-full" placeholder="Last Name" type="text" required /> 
                        </div>
                        <div> 
                          <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-[#858585] px-5 py-4 roboto rounded text-sm w-full" placeholder="Email " type="email" required /> 
                        </div>
                        <div className="lg:col-span-3"> 
                          <textarea rows={6} className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-[#858585] px-5 py-4 roboto rounded text-sm w-full" placeholder="Write and text review here.." defaultValue={""} /> 
                        </div>
                        <div className="lg:col-span-3"> <a href="#" className="bg-[#ff0000] border border-[#ff0000] border-solid font-bold inline-block px-8 py-3 roboto rounded text-base text-center text-white hover:border-white">Post a Comment</a> 
                        </div>
                      </div>                                     
                    </form>
                  </div>
                  <div>
                    <h2 className="font-bold mb-6 text-2xl text-white lg:text-4xl">ALL COMMENTS (123)</h2>
                    <div className="mb-6 lg:mb-8">
                      <h4 className="font-bold mb-1 roboto text-white text-xl">Etiam tempor arcu sed lacinia scelerisque</h4>
                      <p className="mb-3 roboto text-[#a9a9a9] text-sm">Posted by <a href className="font-bold text-white">Noil</a> on Dec 05,2024</p>
                      <p className="roboto text-[#646472] text-sm">The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it’s seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.The passage experience text with their software. Today it’s seen all around the web; on templates, websites, and stock designs. Use our generator to get youhistory of lorem ipsum. <a href className="font-bold text-[#646472]">More</a></p> 
                    </div>
                    <div className="lg:mb-8 mb-6">
                      <h4 className="font-bold mb-1 roboto text-white text-xl">Etiam tempor arcu sed lacinia scelerisque</h4>
                      <p className="mb-3 roboto text-[#a9a9a9] text-sm">Posted by <a href className="font-bold text-white">Noil</a> on Dec 05,2024</p>
                      <p className="roboto text-[#646472] text-sm">The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it’s seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.The passage experience text with their software. Today it’s seen all around the web; on templates, websites, and stock designs. Use our generator to get youhistory of lorem ipsum. <a href className="font-bold text-[#646472]">More</a></p> 
                    </div>
                  </div>
                </section>
                <section>
                  <div className="bg-[#232329] mb-8 p-4 rounded-lg shadow-2xl">
                    <div>
                      <h1 className="font-bold text-2xl text-white uppercase">NEWSLETTER SUBSCRIBE</h1>
                      <p className="mb-4 roboto text-[#666666] text-xs">75,545 are getting the product Updates</p>
                    </div>
                    <div>
                      <form> 
                        <div className="gap-4 grid">
                          <div> 
                            <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="Enter Your Name" required /> 
                          </div>
                          <div> 
                            <input className="appearance-none bg-[#323238] border-[#323238] font-bold outline-none placeholder-opacity-65 placeholder-white px-5 py-4 roboto rounded-md text-sm w-full" placeholder="Enter Your Email" type="email" required /> 
                          </div>
                          <div> <a href="#" className="bg-[#ff0000] border border-[#ff0000] border-solid font-bold inline-block px-8 py-3 roboto rounded text-base text-center text-white hover:border-white">SUBSCRIBE NOW</a> 
                          </div>
                        </div>                                         
                      </form>
                    </div>
                  </div>
                  <div className="bg-[#232329] mb-8 p-4 rounded-lg shadow-2xl">
                    <div>
                      <h1 className="font-bold text-2xl text-white uppercase">CATEGORIES</h1>
                      <p className="mb-4 roboto text-[#666666] text-xs">75,545 are getting the product Updates</p>
                    </div>
                    <div className="font-bold roboto text-sm"><a href className="flex items-center py-1 text-[#aaaaaa] w-full"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="5px" height="8px" className="mr-2"> 
                          <image x="0px" y="0px" width="5px" height="8px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAQAAACb+P2wAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfoCQUTLi1ctel8AAAAT0lEQVQI1yXBoRHCQBAAwL0jJaQBmsC9iKCTDIOiAxw0gERSR1BvviXURbAbo3RnPxLNS5C+ysVDHNaP2UlzTOGvJpsFT/cYhberSnQ3xQ4WxxH2tpNwwgAAAABJRU5ErkJggg==" /> 
                        </svg> Chilli Seeds</a>
                      <a href className="flex items-center py-1 text-[#aaaaaa] w-full"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="5px" height="8px" className="mr-2"> 
                          <image x="0px" y="0px" width="5px" height="8px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAQAAACb+P2wAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfoCQUTLi1ctel8AAAAT0lEQVQI1yXBoRHCQBAAwL0jJaQBmsC9iKCTDIOiAxw0gERSR1BvviXURbAbo3RnPxLNS5C+ysVDHNaP2UlzTOGvJpsFT/cYhberSnQ3xQ4WxxH2tpNwwgAAAABJRU5ErkJggg==" /> 
                        </svg> Chilli Plants</a>
                      <a href className="flex items-center py-1 text-[#aaaaaa] w-full"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="5px" height="8px" className="mr-2"> 
                          <image x="0px" y="0px" width="5px" height="8px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAQAAACb+P2wAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfoCQUTLi1ctel8AAAAT0lEQVQI1yXBoRHCQBAAwL0jJaQBmsC9iKCTDIOiAxw0gERSR1BvviXURbAbo3RnPxLNS5C+ysVDHNaP2UlzTOGvJpsFT/cYhberSnQ3xQ4WxxH2tpNwwgAAAABJRU5ErkJggg==" /> 
                        </svg> Plant by Country</a>
                    </div>
                  </div>
                  <div className="bg-[#232329] mb-8 p-4 rounded-lg shadow-2xl">
                    <div>
                      <h1 className="font-bold mb-3 text-2xl text-white uppercase">Recent Post</h1>
                    </div>
                    <div>
                      <div>
                        <div className="gap-3 grid grid-cols-2">
                          <div>
                            <img src={placeholder} width={280} height={280} className="block h-full object-cover w-full" />
                          </div>
                          <div>
                            <h2 className="font-bold line-clamp-3 roboto text-[#aaaaaa] md:text-base xl:text-xs">Trauma Informed group music therapy program designed </h2>
                            <p className="font-medium mb-2 roboto text-[#7d7d7d] text-xs">2 january 2024</p>
                          </div>
                        </div>
                      </div>
                      <hr className="border-[#eaeaea] border-opacity-15 my-4" />
                      <div>
                        <div className="gap-3 grid grid-cols-2">
                          <div>
                            <img src={placeholder} width={280} height={280} className="block h-full object-cover w-full" />
                          </div>
                          <div>
                            <h2 className="font-bold line-clamp-3 roboto text-[#aaaaaa] md:text-base xl:text-xs">Trauma Informed group music therapy program designed </h2>
                            <p className="font-medium mb-2 roboto text-[#7d7d7d] text-xs">2 january 2024</p>
                          </div>
                        </div>
                      </div>
                      <hr className="border-[#eaeaea] border-opacity-15 my-4" />
                      <div>
                        <div className="gap-3 grid grid-cols-2">
                          <div>
                            <img src={placeholder} width={280} height={280} className="block h-full object-cover w-full" />
                          </div>
                          <div>
                            <h2 className="font-bold line-clamp-3 roboto text-[#aaaaaa] md:text-base xl:text-xs">Trauma Informed group music therapy program designed </h2>
                            <p className="font-medium mb-2 roboto text-[#7d7d7d] text-xs">2 january 2024</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>                     
            </div>                 
          </div>             
        </section>                 
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default Blogdetail;