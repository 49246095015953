function Faq() {
  return (
      <>
        <div id="accordion-collapse" data-accordion="collapse" className="roboto">
          <div className="bg-[#232329] mb-3">
            <h2 id="accordion-collapse-heading-1"> <button type="button" className="border border-[#f1ded1] border-opacity-25 flex font-bold gap-3 items-center justify-between p-5 roboto rounded-tl rounded-tr text-white w-full focus:ring-4 focus:ring-gray-200" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                <span>What is Flowbite?</span>
                <svg data-accordion-icon className="h-3 shrink-0 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5 5 1 1 5" />
                </svg>
              </button> </h2>
            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
              <div className="border border-[#f1ded1] border-opacity-25 p-5 rounded-bl rounded-br">
                <p className="mb-2 text-opacity-50 text-sm text-white dark:text-gray-400">Quisque hendrerit egestas leo sit amet tincidunt. In eget tempus ipsum. Vestibulum vel blandit augue. Sed mi leo, congue hendrerit est vitae, facilisis iaculis libero. Integer id sapien neque. Fusce turpis ex, imperdiet quis molestie vel, venenatis in ligula. Aliquam vel porttitor mauris, eu rutrum enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet eleifend tellus. Fusce quis urna laoreet, vestibulum nibh sit amet, scelerisque nulestibulum vel blandit augue. Sed mi leo, congue hendrerit est vitae, facilisis iaculis libero. Integer id sapien neque. Fusce turpis ex, imperdiet quis molestie vel, venenatis in ligula. Aliquam vel porttitor mauris, eu rutrum enim.</p>
              </div>
            </div>
          </div>
          <div className="bg-[#232329] mb-3">
            <h2 id="accordion-collapse-heading-1"> <button type="button" className="border border-[#f1ded1] border-opacity-25 flex font-bold gap-3 items-center justify-between p-5 roboto rounded-tl rounded-tr text-white w-full focus:ring-4 focus:ring-gray-200" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                <span>What is Flowbite?</span>
                <svg data-accordion-icon className="h-3 rotate-180 shrink-0 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5 5 1 1 5" />
                </svg>
              </button> </h2>
            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1" className="hidden">
              <div className="border border-[#f1ded1] border-opacity-25 p-5 rounded-bl rounded-br">
                <p className="mb-2 text-opacity-50 text-sm text-white dark:text-gray-400">Quisque hendrerit egestas leo sit amet tincidunt. In eget tempus ipsum. Vestibulum vel blandit augue. Sed mi leo, congue hendrerit est vitae, facilisis iaculis libero. Integer id sapien neque. Fusce turpis ex, imperdiet quis molestie vel, venenatis in ligula. Aliquam vel porttitor mauris, eu rutrum enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet eleifend tellus. Fusce quis urna laoreet, vestibulum nibh sit amet, scelerisque nulestibulum vel blandit augue. Sed mi leo, congue hendrerit est vitae, facilisis iaculis libero. Integer id sapien neque. Fusce turpis ex, imperdiet quis molestie vel, venenatis in ligula. Aliquam vel porttitor mauris, eu rutrum enim.</p>
              </div>
            </div>
          </div>
        </div>              
      </>
  );
}
  
export default Faq;