import Header from '../components/header';
import Footer from '../components/footer';

function About() { 
  return (
    <>
      <Header></Header>           
      { /* MAP */ }      
      <section className="map-home"> 
        <iframe allowFullScreen frameBorder={0} src="https://cdn.bootstrapstudio.io/placeholders/map.html" width="100%" height={400} />             
      </section>            
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default About;