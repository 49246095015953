import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const Accounts = () => {
    const [accounts, setAccounts] = useState([]);
    const [newAccount, setNewAccount] = useState({
        first_name: '',
        last_name: '',
        business_name: '',
        business_abn: '',
        phone: '',
        email: '',
        account_address_id: ''
    });
    const [editingAccount, setEditingAccount] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchAccounts();
        fetchAddresses();
    }, []);

    const fetchAccounts = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAccounts(response.data);
        } catch (error) {
            console.error('Error fetching accounts:', error);
            setError('Failed to fetch accounts. Please try again.');
        }
    };

    const fetchAddresses = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account_address`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAddresses(response.data);
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingAccount({ ...editingAccount, [name]: value });
        } else {
            setNewAccount({ ...newAccount, [name]: value });
        }
    };

    const handleAddAccount = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/user/account`, newAccount, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewAccount({
                first_name: '',
                last_name: '',
                business_name: '',
                business_abn: '',
                phone: '',
                email: '',
                account_address_id: ''
            });
            fetchAccounts();
        } catch (error) {
            console.error('Error adding account:', error);
            setError('Failed to add account. Please try again.');
        }
    };

    const handleUpdateAccount = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/user/account/${editingAccount.id}`, editingAccount, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingAccount(null);
            fetchAccounts();
        } catch (error) {
            console.error('Error updating account:', error);
            setError('Failed to update account. Please try again.');
        }
    };

    const handleDeleteAccount = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/user/account/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchAccounts();
        } catch (error) {
            console.error('Error deleting account:', error);
            setError('Failed to delete account. Please try again.');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Accounts</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            <form onSubmit={handleAddAccount} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Account</h3>
                <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={newAccount.first_name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={newAccount.last_name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <input
                    type="text"
                    name="business_name"
                    placeholder="Business Name"
                    value={newAccount.business_name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                />
                <input
                    type="text"
                    name="business_abn"
                    placeholder="Business ABN"
                    value={newAccount.business_abn}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                />
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={newAccount.phone}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={newAccount.email}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <select
                    name="account_address_id"
                    value={newAccount.account_address_id}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                >
                    <option value="">Select Address</option>
                    {addresses.map(address => (
                        <option key={address.id} value={address.id}>
                            {address.street}, {address.suburb_city}, {address.postcode}
                        </option>
                    ))}
                </select>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Account</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Name</th>
                        <th className="p-2">Business</th>
                        <th className="p-2">Phone</th>
                        <th className="p-2">Email</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.map((account, index) => (
                        <tr 
                            key={account.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{account.id}</td>
                            <td className="p-2">{account.first_name} {account.last_name}</td>
                            <td className="p-2">{account.business_name || 'N/A'}</td>
                            <td className="p-2">{account.phone}</td>
                            <td className="p-2">{account.email}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingAccount(account)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteAccount(account.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingAccount && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Account</h3>
                        <form onSubmit={handleUpdateAccount}>
                            <input
                                type="text"
                                name="first_name"
                                placeholder="First Name"
                                value={editingAccount.first_name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="last_name"
                                placeholder="Last Name"
                                value={editingAccount.last_name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="business_name"
                                placeholder="Business Name"
                                value={editingAccount.business_name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                            />
                            <input
                                type="text"
                                name="business_abn"
                                placeholder="Business ABN"
                                value={editingAccount.business_abn}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                            />
                            <input
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                value={editingAccount.phone}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={editingAccount.email}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <select
                                name="account_address_id"
                                value={editingAccount.account_address_id}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            >
                                <option value="">Select Address</option>
                                {addresses.map(address => (
                                    <option key={address.id} value={address.id}>
                                        {address.street}, {address.suburb_city}, {address.postcode}
                                    </option>
                                ))}
                            </select>
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingAccount(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Accounts;