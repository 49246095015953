import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AccountUsers from './account-users';
import AccountRoles from './account-roles';
import Accounts from './accounts';
import AccountAddresses from './account-addresses';
import AccountAddressStates from './account-address-states';
import AccountAddressCountries from './account-address-countries';

const UsersDashboard = () => {
    const [activeComponent, setActiveComponent] = useState('AccountUsers');
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
    };

    const renderActiveComponent = () => {
        switch(activeComponent) {
            case 'AccountUsers': return <AccountUsers />;
            case 'AccountRoles': return <AccountRoles />;
            case 'Accounts': return <Accounts />;
            case 'AccountAddresses': return <AccountAddresses />;
            case 'AccountAddressStates': return <AccountAddressStates />;
            case 'AccountAddressCountries': return <AccountAddressCountries />;
            default: return <AccountUsers />;
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6 text-white">
                <h1 className="text-2xl font-bold">Users Dashboard</h1>
                <div>
                <Link 
                        to="/admin/users/dashboard"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                        Users Dashboard
                    </Link>
                    <Link 
                        to="/admin/chillis/dashboard"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                        Chilli Dashboard
                    </Link>
                <button 
                    onClick={handleLogout}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                    Logout
                </button>
                </div>
            </div>
            
            <div className="mb-4">
                {['AccountUsers', 'AccountRoles', 'Accounts', 'AccountAddresses', 'AccountAddressStates', 'AccountAddressCountries'].map(component => (
                    <button 
                        key={component}
                        onClick={() => setActiveComponent(component)} 
                        className={`mr-2 mb-2 ${activeComponent === component ? 'bg-blue-500 text-white' : 'bg-gray-200'} px-4 py-2 rounded text-black`}
                    >
                        {component}
                    </button>
                ))}
            </div>

            {renderActiveComponent()}
        </div>
    );
};

export default UsersDashboard;