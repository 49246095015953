import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const AccountRoles = () => {
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState({ slug: '', name: '' });
    const [editingRole, setEditingRole] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account_user_role`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
            setError('Failed to fetch roles. Please try again.');
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingRole({ ...editingRole, [name]: value });
        } else {
            setNewRole({ ...newRole, [name]: value });
        }
    };

    const handleAddRole = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/user/account_user_role`, newRole, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewRole({ slug: '', name: '' });
            fetchRoles();
        } catch (error) {
            console.error('Error adding role:', error);
            setError('Failed to add role. Please try again.');
        }
    };

    const handleUpdateRole = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/user/account_user_role/${editingRole.id}`, editingRole, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingRole(null);
            fetchRoles();
        } catch (error) {
            console.error('Error updating role:', error);
            setError('Failed to update role. Please try again.');
        }
    };

    const handleDeleteRole = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/user/account_user_role/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchRoles();
        } catch (error) {
            console.error('Error deleting role:', error);
            setError('Failed to delete role. Please try again.');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Account User Roles</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            <form onSubmit={handleAddRole} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Role</h3>
                <input
                    type="text"
                    name="slug"
                    placeholder="Slug"
                    value={newRole.slug}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={newRole.name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Role</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Slug</th>
                        <th className="p-2">Name</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {roles.map((role, index) => (
                        <tr 
                            key={role.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{role.id}</td>
                            <td className="p-2">{role.slug}</td>
                            <td className="p-2">{role.name}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingRole(role)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteRole(role.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingRole && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Role</h3>
                        <form onSubmit={handleUpdateRole}>
                            <input
                                type="text"
                                name="slug"
                                placeholder="Slug"
                                value={editingRole.slug}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={editingRole.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingRole(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountRoles;