import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ADMIN_API_URL } from '../config/api';
import Header from '../components/seedling-header';
import Footer from '../components/seedling-footer';

const Seedling = () => {
    const { uniqueId } = useParams();
    const [seedling, setSeedling] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSeedling = async () => {
            try {
                const response = await axios.get(`${ADMIN_API_URL}/chilli/seedling/uid/${uniqueId}`);
                setSeedling(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch seedling data');
                setLoading(false);
            }
        };

        if (uniqueId) {
            fetchSeedling();
        }
    }, [uniqueId]);

    const renderContent = () => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>{error}</div>;
        if (!seedling) return <div>No seedling found with this unique ID</div>;

        return (
            <div className="container mx-auto px-4 py-8 text-white">
                <h1 className="text-3xl font-bold mb-4">Seedling Details</h1>
                <p className="mb-2"><strong>Unique ID:</strong> {seedling.unique_id}</p>
                <p className="mb-2"><strong>Chilli:</strong> {seedling.chilli_name}</p>
                <p className="mb-2"><strong>Status:</strong> {seedling.status_name}</p>
                <p className="mb-2"><strong>Date Planted:</strong> {seedling.date_planted}</p>
                {/* Add more seedling details as needed */}
            </div>
        );
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
                {renderContent()}
            </main>
            <Footer />
        </div>
    );
};

export default Seedling;