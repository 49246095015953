import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const AccountAddressCountries = () => {
    const [countries, setCountries] = useState([]);
    const [newCountry, setNewCountry] = useState({ name: '', active: true });
    const [editingCountry, setEditingCountry] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/user/account_address_country`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
            setError('Failed to fetch countries. Please try again.');
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        if (isEditing) {
            setEditingCountry({ ...editingCountry, [name]: inputValue });
        } else {
            setNewCountry({ ...newCountry, [name]: inputValue });
        }
    };

    const handleAddCountry = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/user/account_address_country`, newCountry, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewCountry({ name: '', active: true });
            fetchCountries();
        } catch (error) {
            console.error('Error adding country:', error);
            setError('Failed to add country. Please try again.');
        }
    };

    const handleUpdateCountry = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/user/account_address_country/${editingCountry.id}`, editingCountry, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingCountry(null);
            fetchCountries();
        } catch (error) {
            console.error('Error updating country:', error);
            setError('Failed to update country. Please try again.');
        }
    };

    const handleDeleteCountry = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/user/account_address_country/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchCountries();
        } catch (error) {
            console.error('Error deleting country:', error);
            setError('Failed to delete country. Please try again.');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Account Address Countries</h2>
            {error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{error}</div>}
            
            <form onSubmit={handleAddCountry} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Country</h3>
                <input
                    type="text"
                    name="name"
                    placeholder="Country Name"
                    value={newCountry.name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2 bg-gray-700 text-white"
                    required
                />
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        name="active"
                        checked={newCountry.active}
                        onChange={handleInputChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2 text-white">Active</span>
                </label>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded ml-2">Add Country</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Name</th>
                        <th className="p-2">Active</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {countries.map((country, index) => (
                        <tr 
                            key={country.id}
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{country.id}</td>
                            <td className="p-2">{country.name}</td>
                            <td className="p-2">
                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${country.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                    {country.active ? 'Active' : 'Inactive'}
                                </span>
                            </td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingCountry(country)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteCountry(country.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingCountry && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Country</h3>
                        <form onSubmit={handleUpdateCountry}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Country Name"
                                value={editingCountry.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <label className="inline-flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    name="active"
                                    checked={editingCountry.active}
                                    onChange={(e) => handleInputChange(e, true)}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <span className="ml-2">Active</span>
                            </label>
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingCountry(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountAddressCountries;