import { NavLink, Outlet, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { useState } from "react";

function Header() {
  const location = useLocation();
  const activeKey = location.pathname === '/' ? '/home' : location.pathname;
  const [show, setShow] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const navLinkStyles = ({ isActive }) => {
    return isActive ? "p-2 font-bold lg:font-normal text-[#bd080c] lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]" : "p-2 font-bold lg:font-normal text-black lg:text-white uppercase lg:text-base xl:px-4 xl:text-lg hover:text-[#bd080c]";
  }

  return (
    <>
      <header>
        <section className="bg-[#1a1a1a] hidden py-4 lg:flex">
          <div className="container mx-auto px-4">
            <div className="flex justify-center items-center" data-name="nav-menu">
              <div className="text-center">
                <a href={activeKey} className="inline-block navbar-brand">
                  <img src={logo} width={85} height={100} alt="Logo" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <nav className="bg-[#1a1a1a] py-4 lg:hidden">
          <div className="container mx-auto px-4 w-full">
            <div className="flex items-center justify-center" data-name="nav-menu">
              <a href="#" className="inline-block navbar-brand">
                <img src={logo} width={38} height={45} alt="Logo" />
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;