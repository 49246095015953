import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Faqs from './pages/faqs';
import Contact from './pages/contact';
import About from './pages/about';
import Terms from './pages/terms';
import Blog from './pages/blog';
import Blogdetail from './pages/blog-detail';
import Product from './pages/product';
import Productdetail from './pages/product-detail';
import Seedling from './pages/seedling';
import AdminLogin from './components/admin/admin-login';
import AdminDashboard from './components/admin/admin-dashboard';
import ChilliDashboard from './components/admin/chilli-dashboard';
import UsersDashboard from './components/admin/users-dashboard';

import './assets/css/styles.css';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = () => {
    return localStorage.getItem('adminToken') !== null;
  };

  return isAuthenticated() ? children : <Navigate to="/admin/login" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/faqs" element={<ProtectedRoute><Faqs /></ProtectedRoute>} />
        <Route path="/about" element={<ProtectedRoute><About /></ProtectedRoute>} />
        <Route path="/contact" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
        <Route path="/terms" element={<ProtectedRoute><Terms /></ProtectedRoute>} />
        <Route path="/blog" element={<ProtectedRoute><Blog /></ProtectedRoute>} />
        <Route path="/blog-detail" element={<ProtectedRoute><Blogdetail /></ProtectedRoute>} />
        <Route path="/product" element={<ProtectedRoute><Product /></ProtectedRoute>} />
        <Route path="/product-detail" element={<ProtectedRoute><Productdetail /></ProtectedRoute>} />
        <Route path="/seedling" element={<ProtectedRoute><Productdetail /></ProtectedRoute>} />
        <Route path="/seedling/:uniqueId" element={<Seedling />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/users/dashboard" element={<ProtectedRoute><UsersDashboard /></ProtectedRoute>} />
        <Route path="/admin/dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
        <Route path="/admin/chillis/dashboard" element={<ProtectedRoute><ChilliDashboard /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}

export default App;