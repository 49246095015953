import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const ChilliSpecies = () => {
    const [species, setSpecies] = useState([]);
    const [newSpecies, setNewSpecies] = useState({ name: '' });
    const [editingSpecies, setEditingSpecies] = useState(null);

    useEffect(() => {
        fetchSpecies();
    }, []);

    const fetchSpecies = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli_species`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSpecies(response.data);
        } catch (error) {
            console.error('Error fetching chilli species:', error);
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingSpecies({ ...editingSpecies, [name]: value });
        } else {
            setNewSpecies({ ...newSpecies, [name]: value });
        }
    };

    const handleAddSpecies = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/chilli/chilli_species`, newSpecies, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewSpecies({ name: '' });
            fetchSpecies();
        } catch (error) {
            console.error('Error adding chilli species:', error);
        }
    };

    const handleUpdateSpecies = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/chilli/chilli_species/${editingSpecies.id}`, editingSpecies, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingSpecies(null);
            fetchSpecies();
        } catch (error) {
            console.error('Error updating chilli species:', error);
        }
    };

    const handleDeleteSpecies = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/chilli_species/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchSpecies();
        } catch (error) {
            console.error('Error deleting chilli species:', error);
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Chilli Species</h2>
            
            <form onSubmit={handleAddSpecies} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Species</h3>
                <input
                    type="text"
                    name="name"
                    placeholder="Species Name"
                    value={newSpecies.name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Species</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Name</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {species.map((item, index) => (
                        <tr 
                            key={item.id} 
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{item.id}</td>
                            <td className="p-2">{item.name}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingSpecies(item)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteSpecies(item.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingSpecies && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Species</h3>
                        <form onSubmit={handleUpdateSpecies}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Species Name"
                                value={editingSpecies.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingSpecies(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChilliSpecies;