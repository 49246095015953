import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ADMIN_API_URL } from '../../config/api';

const ChilliGerminationTime = () => {
    const [germinationTimes, setGerminationTimes] = useState([]);
    const [newGerminationTime, setNewGerminationTime] = useState({ min_days: '', max_days: '', name: '' });
    const [editingGerminationTime, setEditingGerminationTime] = useState(null);

    useEffect(() => {
        fetchGerminationTimes();
    }, []);

    const fetchGerminationTimes = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${ADMIN_API_URL}/chilli/chilli_germination_time`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setGerminationTimes(response.data);
        } catch (error) {
            console.error('Error fetching chilli germination times:', error);
        }
    };

    const handleInputChange = (e, isEditing = false) => {
        const { name, value } = e.target;
        if (isEditing) {
            setEditingGerminationTime({ ...editingGerminationTime, [name]: value });
        } else {
            setNewGerminationTime({ ...newGerminationTime, [name]: value });
        }
    };

    const handleAddGerminationTime = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.post(`${ADMIN_API_URL}/chilli/chilli_germination_time`, newGerminationTime, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewGerminationTime({ min_days: '', max_days: '', name: '' });
            fetchGerminationTimes();
        } catch (error) {
            console.error('Error adding chilli germination time:', error);
        }
    };

    const handleUpdateGerminationTime = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('adminToken');
            await axios.put(`${ADMIN_API_URL}/chilli/chilli_germination_time/${editingGerminationTime.id}`, editingGerminationTime, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEditingGerminationTime(null);
            fetchGerminationTimes();
        } catch (error) {
            console.error('Error updating chilli germination time:', error);
        }
    };

    const handleDeleteGerminationTime = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            await axios.delete(`${ADMIN_API_URL}/chilli/chilli_germination_time/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchGerminationTimes();
        } catch (error) {
            console.error('Error deleting chilli germination time:', error);
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4 text-white">Chilli Germination Times</h2>
            
            <form onSubmit={handleAddGerminationTime} className="mb-8">
                <h3 className="text-lg font-bold mb-2 text-white">Add New Germination Time</h3>
                <input
                    type="number"
                    name="min_days"
                    placeholder="Minimum Days"
                    value={newGerminationTime.min_days}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2"
                    required
                />
                <input
                    type="number"
                    name="max_days"
                    placeholder="Maximum Days"
                    value={newGerminationTime.max_days}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2"
                    required
                />
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={newGerminationTime.name}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 mr-2"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">Add Germination Time</button>
            </form>

            <table className="w-full text-white text-left">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="p-2">ID</th>
                        <th className="p-2">Name</th>
                        <th className="p-2">Min Days</th>
                        <th className="p-2">Max Days</th>
                        <th className="p-2 text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {germinationTimes.map((item, index) => (
                        <tr 
                            key={item.id} 
                            className={`
                                ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}
                                hover:bg-gray-600 transition-colors duration-200
                            `}
                        >
                            <td className="p-2">{item.id}</td>
                            <td className="p-2">{item.name}</td>
                            <td className="p-2">{item.min_days}</td>
                            <td className="p-2">{item.max_days}</td>
                            <td className="p-2 text-right">
                                <button onClick={() => setEditingGerminationTime(item)} className="text-blue-500 mr-2">Edit</button>
                                <button onClick={() => handleDeleteGerminationTime(item.id)} className="text-red-500">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingGerminationTime && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <h3 className="text-lg font-bold mb-4">Edit Germination Time</h3>
                        <form onSubmit={handleUpdateGerminationTime}>
                            <input
                                type="number"
                                name="min_days"
                                placeholder="Minimum Days"
                                value={editingGerminationTime.min_days}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="number"
                                name="max_days"
                                placeholder="Maximum Days"
                                value={editingGerminationTime.max_days}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={editingGerminationTime.name}
                                onChange={(e) => handleInputChange(e, true)}
                                className="border p-2 mb-2 w-full"
                                required
                            />
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                <button onClick={() => setEditingGerminationTime(null)} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChilliGerminationTime;